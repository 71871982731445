// @flow
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {translateChildren} from "./wrapperUtil";

type MdLinkProps = {
  href: string,
  target?: string,
  enableTranslate?: boolean,
} & React.HTMLAttributes<HTMLAnchorElement>;


export const MdLink = ({
                         children,
                         href,
                         target = "_black",
                         enableTranslate = true,
                         ...rest
                       }: MdLinkProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);


  return (
      <a href={href} target={target} {...rest}>{translatedChildren}</a>
  );
};