import React from "react";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import {
  CloseableDialogProps,
  DialogNames,
  openDialog
} from "../../../component/dialog/DialogManager";
import {useAutoFocus, useFieldsValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {validatePassword, validateUsername} from "../../../api/util/Validation";
import Divider from "@mui/material/Divider";
import LockIcon from '@mui/icons-material/Lock';
import DimensionForm from "../../../component/container/DimensionForm";
import ClearableTextField from "../../../component/input/ClearableTextField";
import PasswordTextField from "../../../component/input/PasswordTextField";
import {SIGN_UP_RECAPTCHA_ACTION, signUp} from "../service/AuthenticationService";
import {AuthenticationRequest, AuthenticationType} from "../../../proto/framework/SecurityMessage";
import {ExceptionResponse} from "../../../proto/framework/ExceptionMessage";
import {print} from "../../../api/util/Logging";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Button, Typography} from "../../../component/wrapper/MuiWrappers";
import {useTranslation} from "react-i18next";
import {MdLink} from "../../../component/wrapper/MdLink";
import {Stack} from "@mui/material";

const SignUpDialog = ({open, onDialogClose = void 0, sx, ...rest}: CloseableDialogProps) => {

  const handleClose = useHandleDialogClose(DialogNames.SignUpDialog, onDialogClose);
  const inputRef = useAutoFocus([open]);
  const {executeRecaptcha} = useGoogleReCaptcha();
  const {t, i18n} = useTranslation();

  const [username, setUsername, usernameErrorMessage,
    password, setPassword, passwordErrorMessage,
    confirmPassword, setConfirmPassword, confirmPasswordErrorMessage,
    errorMessage, setErrorMessage, validate] = useFieldsValidation([
    (username) => {
      if (username.length === 0) {
        return "Username cannot be empty";
      }
      const isUsernameValid = validateUsername(username);
      if (!isUsernameValid) {
        return "Username is invalid";
      }
    },
    (password) => {
      if (password.length === 0) {
        return "Password cannot be empty";
      }
      const isPasswordValid = validatePassword(password);
      if (!isPasswordValid) {
        return "Password is too simple, please combine with letters, numbers and special characters";
      }
    },
    (confirmPassword) => {
      if (confirmPassword.length === 0) {
        return "Confirm Password cannot be empty";
      }
    },
  ], ([username, password, confirmPassword]) => {
    if (confirmPassword !== password) {
      return "Confirm Password does not match";
    }
  });

  const handleReCaptchaVerify = async () => {
    if (!validate()) {
      return;
    }

    if (!executeRecaptcha) {
      print('Execute recaptcha not yet available');
      setErrorMessage("Something went wrong, please try again later");
      // use traditional captcha handling / skip
      return;
    }

    // https://cloud.google.com/recaptcha/docs/actions-website
    const token = await executeRecaptcha(SIGN_UP_RECAPTCHA_ACTION);
    // Do whatever you want with the token
    onSubmit(token);

  };

  const onSubmit = (reCaptchaToken: string) => {

    const request = AuthenticationRequest.create();
    request.authenticationType = AuthenticationType.PASSWORD;
    request.userIdentifier = username;
    request.credential = password;
    request.rememberMe = true;
    return signUp(request, reCaptchaToken).then(() => {
      handleClose(true);
    }).catch((error: ExceptionResponse) => {
      setErrorMessage(error.message);
    });
  }

  return <DimensionDialog
      dialogTitle={"Sign Up"}
      dialogStartNode={<Divider><LockIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      onDialogSubmit={handleReCaptchaVerify}
      open={open}
      sx={sx}
      {...rest}
  >
    <DimensionForm errorMessage={errorMessage}>
      <ClearableTextField value={username} setValue={setUsername} label={"username"}
                          onSubmit={handleReCaptchaVerify} errorMessage={usernameErrorMessage}
                          inputRef={inputRef}/>

      <PasswordTextField value={password} errorMessage={passwordErrorMessage}
                         onSubmit={handleReCaptchaVerify}
                         setValue={setPassword}/>

      <PasswordTextField value={confirmPassword}
                         errorMessage={confirmPasswordErrorMessage}
                         label={"Confirm Password"}
                         onSubmit={handleReCaptchaVerify}
                         setValue={setConfirmPassword}/>
      <Stack direction={"column"}>
        {i18n.language === "zh-CN" ? <Typography variant={"subtitle2"} textAlign={"center"}>
          本站点受reCAPTCHA保护，并适用Google<MdLink
            href="https://policies.google.com/privacy">《隐私权政策》</MdLink>和
          <MdLink href="https://policies.google.com/terms">《服务条款》</MdLink>.
        </Typography> : <Typography variant={"subtitle2"} textAlign={"center"}>
          This site is protected by reCAPTCHA and the Google <MdLink
            href="https://policies.google.com/privacy">Privacy Policy</MdLink> and&nbsp;
          <MdLink href="https://policies.google.com/terms">Terms of Service</MdLink> apply.
        </Typography>}
        <Typography variant={"subtitle2"} textAlign={"center"}>
          Already have an account? <Button onClick={() => {
          handleClose(false);
          openDialog(DialogNames.SignInDialog);
        }} variant={"text"} disableElevation={true} color={"primary"}>{"Sign In"}</Button>
        </Typography>
      </Stack>


      {/*<CaptchaCodeField value={captchaCode} errorMessage={captchaCodeErrorMessage}*/}
      {/*                  onSubmit={handleReCaptchaVerify} setValue={setCaptchaCode}/>*/}


      {/*<Stack direction={"column"} spacing={1}>*/}
      {/*  <Typography variant={"subtitle2"}*/}
      {/*              textAlign={"center"}>{"Not yet have an account? Sign Up "}<Link*/}
      {/*      onClick={() => {*/}
      {/*        openDialog(DialogNames.SignUpDialog);*/}
      {/*      }}>{"here"}</Link></Typography>*/}
      {/*  <Typography variant={"subtitle2"}*/}
      {/*              textAlign={"center"}>{"Forget Password? "}<Link*/}
      {/*      onClick={() => {*/}
      {/*        openDialog(DialogNames.ForgetPasswordDialog);*/}
      {/*      }}>{"Recover here"}</Link></Typography>*/}
      {/*</Stack>*/}

    </DimensionForm>

  </DimensionDialog>;
}

export default React.memo(SignUpDialog);