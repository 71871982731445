import {Avatar, AvatarProps} from "@mui/material";
import React from "react";
import {toColor} from "../../../api/util/ColorUtil";
import PlainLink from "../../../component/wrapper/PlainLink";

type ProfileAvatarProps = {
  title?: string;
  avatarUrl?: string;
  profileLink?: string;
} & AvatarProps;

const ProfileAvatar = ({
                         title = "User",
                         avatarUrl = void 0,
                         profileLink = void 0,
                         sx, ...rest
                       }: ProfileAvatarProps) => {

  const hasAvatar = avatarUrl && avatarUrl.length > 0;
  const avatarContent = hasAvatar ? <Avatar title={title} src={avatarUrl} alt={title}
                                                sx={{
                                                  backgroundColor: "transparent",
                                                  ...sx}} {...rest}></Avatar> :
      <Avatar title={title}
              sx={{bgcolor: toColor(title), ...sx}} {...rest}>{title.toUpperCase().slice(0, 1)}</Avatar>;

  return profileLink ? <PlainLink to={profileLink}
                                  enableTranslate={false}>{avatarContent}</PlainLink> : avatarContent;


}

export default ProfileAvatar;