import {useTheme} from "@mui/material";
import React from "react";
import {validatePassword} from "../../../api/util/Validation";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useAutoFocus, useFieldsValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {CloseableDialogProps, DialogNames} from "../../../component/dialog/DialogManager";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import DimensionForm from "../../../component/container/DimensionForm";
import {updatePassword} from "../service/AuthenticationService";
import PasswordTextField from "../../../component/input/PasswordTextField";

const EditPasswordDialog = ({onDialogClose, open, ...rest}: CloseableDialogProps) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose(DialogNames.EditPasswordDialog, onDialogClose);
  const inputRef = useAutoFocus([open]);

  const [newPassword, setNewPassword, newPasswordErrorMessage,
    confirmPassword, setConfirmPassword, confirmPasswordErrorMessage,
    errorMessage, setErrorMessage, validate] = useFieldsValidation([
    (newPassword) => {
      if (newPassword.length === 0) {
        return "New Password is Empty";
      }
      const isPasswordValid = validatePassword(newPassword);
      if (!isPasswordValid) {
        return "Password is too simple.";
      }
    },
    (confirmPassword) => {
      if (confirmPassword.length === 0) {
        return "Confirm Password is Empty";
      }
    }
  ], ([newPassword, confirmPassword]) => {
    if (confirmPassword !== newPassword) {
      return "Confirm Password does not match";
    }
  });

  const handleEditPassword = async () => {
    if (!validate()) {
      return;
    }

    updatePassword(newPassword).then(response => {
      handleClose(true);
    }).catch(error => {
      setErrorMessage(error.message);
    });
  }

  return <DimensionDialog
      dialogTitle={"Change Password"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleEditPassword}
      {...rest}>
    <DimensionForm
        infoMessage={"Password must be at least 6 characters long, and must include at least two types of characters from uppercase letter, lowercase letter, number and and punctuation"}
        errorMessage={errorMessage}>
      <PasswordTextField value={newPassword}
                         inputRef={inputRef}
                         label={"New Password"}
                         errorMessage={newPasswordErrorMessage}
                         onSubmit={handleEditPassword}
                         setValue={setNewPassword}/>

      <PasswordTextField value={confirmPassword}
                         label={"Confirm New Password"}
                         errorMessage={confirmPasswordErrorMessage}
                         onSubmit={handleEditPassword}
                         setValue={setConfirmPassword}/>
    </DimensionForm>
  </DimensionDialog>
}

export default React.memo(EditPasswordDialog);