import React from 'react';
import './App.css';
import './api/i18n/i18n';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import store, {Persistor} from "./api/redux/store";
import {ThemeProvider} from "@mui/material";
import {defaultTheme} from "./context/ThemeContext";
import AlertContext, {GlobalAlert} from "./context/AlertContext";
import GlobalProviderContainer from "./context/GlobalProviderContainer";
import {AlertColor} from "@mui/material/Alert/Alert";
import {RouterProvider} from "react-router-dom";
import rootRouter from "./navigation/rootRouter";
import DialogManager from "./component/dialog/DialogManager";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {DEFAULT_CACHE_TIME, enable_client_log} from "./api/applicationConfig";
import {ClientLogger} from "./domain/website/component/ClientLogger";

function App() {
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertText, setAlertText] = React.useState('');
  const [alertColor, setAlertColor] = React.useState<AlertColor>('info');
  const language = localStorage.getItem("language") || "en";
  // const {messages, message, sendSocketMessage} = useWebSocketProvider();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: DEFAULT_CACHE_TIME,
      },
      mutations: {
        gcTime: DEFAULT_CACHE_TIME,
      }
    },
  });
  return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}>
          <GoogleOAuthProvider
              clientId="534113610840-3ho60fao7aluua1prg3ajcketg8n6cau.apps.googleusercontent.com">
            <GoogleReCaptchaProvider
                reCaptchaKey="6Lf7qUkqAAAAADIO8bsHqy4G10gMgiTbwM__bYUI"
                language={language}
                useRecaptchaNet={true}
                useEnterprise={true}
                scriptProps={{
                  async: false, // optional, default to false,
                  defer: false, // optional, default to false
                  appendTo: 'head', // optional, default to "head", can be "head" or "body",
                  nonce: undefined // optional, default undefined
                }}
            >
              <ThemeProvider theme={defaultTheme}>
                <AlertContext.Provider value={{
                  showAlert: (text = "Alert Test", color = "error") => {
                    setAlertText(text);
                    setAlertColor(color);
                    setOpenAlert(true);
                  },
                }}>
                  <GlobalProviderContainer>
                    <ClientLogger init={enable_client_log} freq={1000}/>
                    <QueryClientProvider client={queryClient}>
                        <div className="App">
                          <RouterProvider router={rootRouter}></RouterProvider>
                        </div>
                        <DialogManager></DialogManager>
                    </QueryClientProvider>
                    <GlobalAlert alertText={alertText}
                                 color={alertColor}
                                 open={openAlert}
                                 setOpen={setOpenAlert}></GlobalAlert>
                  </GlobalProviderContainer>
                </AlertContext.Provider>
              </ThemeProvider>
            </GoogleReCaptchaProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
  );
}

export default App;
