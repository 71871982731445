import validator from 'validator';

const usernameRegex = /^[a-zA-Z\u4e00-\u9fa5\d\s!@#$%^&*()_+[\]{}|;:'",.<>?`~/\\-]{2,120}$/u; // 允许中文字符（\u4e00-\u9fa5）
const folderNameRegex = /^[a-zA-Z\u4e00-\u9fa5\d\s#$%^&*()_+?-]{1,120}$/u; // 允许中文字符（\u4e00-\u9fa5）
const fileNameRegex = /^[a-zA-Z\u4e00-\u9fa5\d\s!@#$%^&*()_+.-]{1,120}$/u; // 允许中文字符（\u4e00-\u9fa5）
const sharePasswordRegex = /^[a-zA-Z\u4e00-\u9fa5\d\s!@#$%^&*()_+[\]{}|;:'",.<>?`~/\\-]{4,120}$/u; // 允许中文字符（\u4e00-\u9fa5）


export const validateUsername = (username: string): boolean => {
  // 验证长度和字符规范
  return usernameRegex.test(username);
}

export const validatePassword = (password: string): boolean => {
  // 定义三种字符类型的正则表达式
  const digitRegex = /\d/; // 包含数字
  const letterRegex = /[a-z]/; // 包含英文字符
  const capitalRegex = /[A-Z]/; // 包含特殊字符
  const specialCharRegex = /[!@#$%^&*()_+[\]{}|;:'",.<>?`~/\\-]/; // 包含特殊字符
  const passwordRegex = /[\da-zA-Z!@#$%^&*()_+[\]{}|;:'",.<>?`~/\\-]{6,32}/

  // 计算包含的字符类型数量
  const typeCount = [digitRegex, letterRegex, capitalRegex, specialCharRegex].filter(regex =>
      regex.test(password)
  ).length;

  // 至少包含两种不同类型的字符
  if (typeCount < 2) {
    return false;
  }

  if (!passwordRegex.test(password)) {
    return false;
  }

  return true;
}

export const validateEmail = (email: string): boolean => {
 return validator.isEmail(email);
}

export const validateUrl = (url: string): boolean => {
  return validator.isURL(url);
}