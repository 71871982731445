export const translateChildren = (children: any, enableTranslate: boolean, t: any) => {
  if (typeof children === 'string') {
    return enableTranslate ? t(children) : children;
  }
  if (Array.isArray(children)) {
    return enableTranslate ? children.map((child) => {
      if (typeof child === 'string') {
        return t(child);
      }
      return child;
    }) : children;
  }

  return enableTranslate ? typeof children === 'string' ? t(children) : children : children;

};