export const root_path = "";
export const get_root_url = () => {
  return "/" + root_path;
}
export const get_url = (path: string) => {
  return get_root_url() + path;
}
export const account_path = "account";
export const get_account_url = () => {
  return get_url(account_path);
}
export const policy_path = "policy";
export const get_policy_url = () => {
  return get_url(policy_path);
}

// export const github_oauth_path = "github_oauth";
// export const get_github_oauth_url = () => {
//   return get_url(github_oauth_path);
// }

export const customer_path = "customer";
export const get_customer_url = (customer_id?: string) => {
  if (customer_id) {
    return get_url(customer_path) + "?id=" + customer_id;
  }
  return get_url(customer_path);
}

export const storage_path = "storage";
export const get_storage_url = () => {
  return get_url(storage_path);
}

export const ai_chat_path = "chatgpt";
export const get_ai_chat_url = (thread_id?: string) => {
  if (thread_id) {
    return get_url(ai_chat_path) + "?id=" + thread_id;
  }
  return get_url(ai_chat_path);
}

export const driver_path = "driver";
export const get_driver_url = (path_id?: string) => {
  if (path_id) {
    return get_url(driver_path) + "?id=" + path_id;
  }
  return get_url(driver_path);
}

export const gallery_path = "gallery";
export const get_gallery_url = () => {
  return get_url(gallery_path);
}