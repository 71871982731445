import {Link, LinkProps} from "react-router-dom";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {translateChildren} from "./wrapperUtil";

type PlainLinkProps = {
  to: string;
  children: React.ReactNode;
  enableTranslate?: boolean,
} & LinkProps & React.RefAttributes<HTMLAnchorElement>

const PlainLink = ({to, children, enableTranslate = true, ...rest}: PlainLinkProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return (
      <Link to={to} style={{textDecoration: "none", color: "inherit"}} {...rest}>
        {translatedChildren}
      </Link>
  )
}

export default PlainLink;