import {Stack, StackProps} from "@mui/material";
import FeatureSwitcher from "./FeatureSwitcher";
import {useAppDispatch, useAppSelector} from "../../../api/redux/hooks";
import {
  selectEnableAutoPlay,
  selectEnableDarkMode,
  selectEnableParticle,
  setDarkMode,
  setEnableAutoPlay,
  setEnableParticle
} from "../../../api/redux/slice/SettingSlice";
import LanguageSwitcher from "./LanguageSwitcher";

const FeaturePanel = ({...rest}: StackProps) => {
  const dispatch = useAppDispatch();

  const enableParticle = useAppSelector(selectEnableParticle);

  const setEnableParticles = (enable: boolean) => {
    dispatch(setEnableParticle(enable));
  }

  const enableDarkMode = useAppSelector(selectEnableDarkMode);
  const setEnableDarkMode = (enable: boolean) => {
    dispatch(setDarkMode(enable));
  }

  const enableAutoPlay = useAppSelector(selectEnableAutoPlay);
  const setEnableAutoPlays = (enable: boolean) => {
    dispatch(setEnableAutoPlay(enable));
  }

  return <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} {...rest}>
    <LanguageSwitcher/>
    <FeatureSwitcher featureName={"Dark Mode (might cause lag on page loading)"}
                     featureValue={enableDarkMode}
                     setFeatureValue={setEnableDarkMode}/>
    <FeatureSwitcher featureName={"Allow Auto Play"} featureValue={enableAutoPlay}
                     setFeatureValue={setEnableAutoPlays}/>
    <FeatureSwitcher featureName={"Particle Animation"} featureValue={enableParticle}
                     setFeatureValue={setEnableParticles}/>
  </Stack>
}

export default FeaturePanel;