import {ButtonProps} from "@mui/material/Button/Button";
import {Box} from "@mui/material";
import React, {FC} from "react";
import {Button, Typography} from "../wrapper/MuiWrappers";

// ContentButton 是一个便捷实用按钮组件，它可以包含标题、详情和图标。方便制作各种按钮。
export type ContentButtonProps = {
  title?: string,
  to?: string,
  target?: string,
  detail?: string,
  icon?: React.ReactNode;
} & ButtonProps;

const ContentButton: FC<ContentButtonProps> = ({
                                                 title = void 0,
                                                 to = void 0,
                                                 target = void 0,
                                                 detail = void 0,
                                                 icon = void 0,
                                                 variant = "text",
                                                 color = "inherit",
                                                 size = "large",
                                                 ...rest
                                               }: ContentButtonProps) => {
  // const realComponent = to ? Link : "button";
  if (!title) {
    if (!detail && icon) {
      return <Button variant={variant} size={size} color={color} to={to} target={target}
                     {...rest} >
        {icon}
      </Button>;
    }
    if (detail && !icon) {
      return <Button size={size} variant={variant}
                     color={color} to={to} target={target} {...rest}>
        <Typography variant="body2">{detail}</Typography>
      </Button>
    }
    if (detail && icon) {
      return <Button size={size} color={color} startIcon={icon}
                     variant={variant}
                     to={to} target={target} {...rest}>{detail}</Button>
    }
    return <Button size={size} variant={variant}
                   color={color} to={to} target={target} {...rest}>
      {"Click"}
    </Button>
  }
  if (!detail && !icon) {
    return <Button variant={variant} size={size} color={color} to={to} target={target}
                   {...rest} >
      {title}
    </Button>;
  }
  if (!detail && icon) {
    return <Button size={size} color={color} startIcon={icon}
                   variant={variant}
                   to={to} target={target} {...rest}>{title}</Button>
  }

  if (detail && !icon) {
    return <Button style={{display: "block", textAlign: "left"}} size={size} variant={variant}
                   color={color} to={to} target={target} {...rest}>
      <Typography
          variant="subtitle1"
      >
        {title}
      </Typography>
      <Typography variant="body2">{detail}</Typography>
    </Button>
  }

  return <Button startIcon={icon} size={size} color={color}
                 variant={variant}
                 to={to} target={target} {...rest}>
    <Box textAlign={"start"} marginLeft={1}>
      <Typography
          variant="subtitle1"
      >
        {title}
      </Typography>
      <Typography variant="body2">{detail}</Typography>
    </Box>
  </Button>
}

export default ContentButton;