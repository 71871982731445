import {Stack, StackProps} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {Tooltip, Typography} from "../wrapper/MuiWrappers";

type IconTextButtonProps = {
  hint?: string,
  text?: string,
  direction?: "row" | "column" ;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  variant?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "overline" | "body1" | "subtitle1" | "subtitle2" | "body2" | undefined,
  color?: "default" | "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined,
  icon: React.ReactNode,
} & StackProps;

const IconTextButton = ({
                          hint = void 0,
                          text = void 0,
                          onClick = void 0,
                          direction = "row",
                          icon,
                          variant = "body2",
                          color = "inherit",
                          sx, ...rest
                        }: IconTextButtonProps) => {
  return (
      <Tooltip title={hint ?? text} enableTranslate={false}>
        <Stack direction={direction} alignItems={"center"} sx={{...sx}} {...rest}>
          <IconButton onClick={onClick}
                      color={color}>
            {icon}
          </IconButton>
          {text !== void 0 && <Typography variant={variant}
                               color={color}>
            {text}
          </Typography>}
        </Stack>
      </Tooltip>
  )
}


export default IconTextButton;