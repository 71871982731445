import {useTheme} from "@mui/material";
import React from "react";
import {validateUsername} from "../../../api/util/Validation";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useAutoFocus, useFieldValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {CloseableDialogProps, DialogNames} from "../../../component/dialog/DialogManager";
import ClearableTextField from "../../../component/input/ClearableTextField";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import DimensionForm from "../../../component/container/DimensionForm";
import {updateUsername} from "../service/AuthenticationService";

const EditUsernameDialog = ({onDialogClose, open, ...rest}: CloseableDialogProps) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose(DialogNames.EditUsernameDialog, onDialogClose);


  const [newUsername, setNewUsername, usernameErrorMessage, setUsernameErrorMessage, validate] = useFieldValidation((username) => {
    if (username.length === 0) {
      return "Username cannot be empty";
    }
    if (!validateUsername(username)) {
      return "Username is invalid";
    }
  });

  const inputRef = useAutoFocus([open]);

  const handleEditUsername = async () => {
    if (!validate()) {
      return;
    }

    updateUsername(newUsername).then(response => {
      handleClose(true);
    }).catch(error => {
      setUsernameErrorMessage(error.message);
    });
  }

  return <DimensionDialog
      dialogTitle={"Edit Account name for Login"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleEditUsername}
      {...rest}>
    <DimensionForm
        infoMessage={"Account name is used for login, it must be a unique string."}>
      <ClearableTextField value={newUsername} setValue={setNewUsername} label={"New Username"}
                          onSubmit={handleEditUsername} errorMessage={usernameErrorMessage}
                          inputRef={inputRef}/>
    </DimensionForm>
  </DimensionDialog>
}

export default  React.memo(EditUsernameDialog);