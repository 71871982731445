import React, {ReactNode} from 'react';

interface OneOfProps {
  index: number;
  children: ReactNode | ReactNode[];
}

const OneOf: React.FC<OneOfProps> = ({ index, children }) => {
  return React.Children.toArray(children)[index] as JSX.Element;
  // // Ensure that the index is valid and children is not empty
  // if (index < 0 || index >= children.length) {
  //   throw new Error('Invalid OneOf Index' + index + " > " + children.length);
  // }
  // if (!children || children.length === 0 ) {
  //   return <></>;
  // }
  //
  // // Render the child at the specified index
  // return <>{children[index]}</>;
};

export default OneOf;