import {configureStore} from '@reduxjs/toolkit'
import dialogReducer from './slice/DialogSlice'
import settingReducer from './slice/SettingSlice'
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfigForSetting = {
  key: 'redux-setting',
  storage,
}

const persistedSettingReducer = persistReducer(persistConfigForSetting, settingReducer)

const store = configureStore({
  reducer: {
    // reducer,
    dialogReducer,
    persistedSettingReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export const Persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store