import axios, {AxiosRequestConfig} from "axios";
import {DEFAULT_CACHE_TIME, in_prod, PROD_BACKEND_IP} from "../applicationConfig";
import StatusCodes from "http-status-codes";
import {debounce as lodash_debounce, throttle as lodash_throttle} from "lodash";
import {DialogNames, openDialogWithPromise} from "../../component/dialog/DialogManager";
import {ExceptionResponse} from "../../proto/framework/ExceptionMessage";
import {Buffer} from "buffer";
import {print} from "./Logging";
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {logAction} from "../../context/websocket/WebsocketHook";

const localHost = window.location.host;
export const webHost = window.location.origin; // use for redirect to current page
export const apiHost = in_prod ? `https://${PROD_BACKEND_IP}` : `http://${PROD_BACKEND_IP}:8080`;
// : localHost.includes("3000") ? `http://${localHost.replace('3000', '8080')}` : `http://${localHost}:8080`;
// export const websocketHost = in_prod ? `wss://${localHost}`: `ws://${localHost.replace('3000', '8080')}`;

export const JWT_ID = "Jwt";
export const REMEMBER_ME_ID = "Rememberme";
export const OTP_HEADER = "Otp"

let OTP_ID = "";

const client = axios.create({
  baseURL: apiHost,
});


client.interceptors.request.use(config => {
  const language = localStorage.getItem("language");
  // console.log("config")
  // console.dir(config)
  if (language && !("Accept-Language" in config.headers)) {
    config.headers["Accept-Language"] = language;
  }
  if (!("Accept" in config.headers)) {
    config.headers["Accept"] = "*/*";
  }
  if (!("Content-Type" in config.headers)) {
    config.headers["Content-Type"] = "application/json";
  }
  if (OTP_HEADER in config.headers) {
    config.headers["Oid"] = OTP_ID
  }

  config.withCredentials = true;
  config.headers[JWT_ID] = localStorage.getItem(JWT_ID);
  config.headers[REMEMBER_ME_ID] = localStorage.getItem(REMEMBER_ME_ID);
  return config;
});


// return whether the error is retryable
const errorHandler = (error: any): Promise<boolean> => {
  if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
    return openDialogWithPromise(DialogNames.SignInDialog).then(result => {
      return result.success;
    });

  }
  return Promise.resolve(false);
}

const captchaUrl = (): string => {
  return apiHost + "/captcha.jpg" + "?time=" + Date.now();
}

const toApiUri = (path: string): string => {
  return `/api/v0/${path}`;
}
//
// const apiGet = (apiUri: string, config?: AxiosRequestConfig<any> | undefined) => {
//   return client.get(apiUri, config)
//   .then(res => {
//     return res;
//   })
//   .catch(async (error) => {
//     const retryAble = (apiUri === "/login" || apiUri === "/logout") ? false : await errorHandler(error);
//     if (retryAble) {
//       return client.get(apiUri, config);
//     } else {
//       try {
//         const errorResponse = ExceptionResponse.decode(Buffer.from(error.response.data));
//         print("server error: ");
//         printDir(errorResponse);
//         if (errorResponse.message.includes("at")) {
//           errorResponse.message = errorResponse.message.split("at")[0];
//         }
//         throw errorResponse;
//       } catch (e) {
//         print("client error: ");
//         printDir(e);
//         throw e;
//       }
//     }
//   });
// }

export type ProtoEncode<I> = { encode: (message: I) => BinaryWriter };
export type ProtoDecode<K> = { decode: (input: BinaryReader | Uint8Array, length?: number) => K };


export const pb_strPost = <I>(
    apiUri: string,
    inputType: ProtoEncode<I>,
    data: I,
    config?: AxiosRequestConfig,
): Promise<string> => {
  const newConfig = config ? config : {
    headers: {
      "Content-Type": "application/x-protobuf"
    }
  };
  if (!newConfig.headers) {
    newConfig.headers = {
      "Content-Type": "application/x-protobuf"
    };
  }
  if (!("Content-Type" in newConfig.headers)) {
    newConfig.headers["Content-Type"] = "application/x-protobuf";
  }
  // newConfig.responseType = "arraybuffer";
  return apiPost(apiUri, inputType.encode(data).finish(), newConfig).then(res => {
    return res.data.toString();
  });
};

export const pb_pbPost = <I, K>(
    apiUri: string,
    inputType: ProtoEncode<I>,
    returnType: ProtoDecode<K>,
    data: I,
    config?: AxiosRequestConfig,
): Promise<K> => {
  const newConfig: AxiosRequestConfig = config ? config : {
    headers: {
      "Content-Type": "application/x-protobuf"
    }
  };
  if (!newConfig.headers) {
    newConfig.headers = {
      "Content-Type": "application/x-protobuf"
    };
  }
  if (!("Content-Type" in newConfig.headers)) {
    newConfig.headers["Content-Type"] = "application/x-protobuf";
  }
  newConfig.responseType = "arraybuffer";
  return apiPost(apiUri, inputType.encode(data).finish(), newConfig).then(res => {
    return returnType.decode(Buffer.from(res.data));
  });
};

export const str_pbPost = <K>(
    apiUri: string,
    returnType: ProtoDecode<K>,
    data?: string | number | null,
    config?: AxiosRequestConfig,
): Promise<K> => {
  const newConfig = config ? config : {
    headers: {
      "Content-Type": "text/plain"
    }
  };
  if (!newConfig.headers) {
    newConfig.headers = {
      "Content-Type": "text/plain"
    };
  }
  if (!("Content-Type" in newConfig.headers)) {
    newConfig.headers["Content-Type"] = "text/plain";
  }
  newConfig.responseType = "arraybuffer";
  return apiPost(apiUri, data, newConfig).then(res => {
    return returnType.decode(Buffer.from(res.data));
  });
};


export const str_strPost = (
    apiUri: string,
    data?: string | string[] | number | number[] | null,
    config?: AxiosRequestConfig,
) => {
  const newConfig = config ? config : {
    headers: {
      "Content-Type": "text/plain"
    }
  };
  if (!newConfig.headers) {
    newConfig.headers = {
      "Content-Type": "text/plain"
    };
  }
  if (!("Content-Type" in newConfig.headers)) {
    newConfig.headers["Content-Type"] = "text/plain";
  }
  return apiPost(apiUri, data, newConfig).then(res => {
    return res.data.toString();
  });
}

const logApiCall = (apiUri: string) => {
  logAction(apiUri, "api_call");
}

const logApiError = (apiUri: string, msg: string) => {
  logAction("[" + apiUri + "]:" + msg, "api_error");
}

const apiPost = (apiUri: string, data?: any, config?: AxiosRequestConfig<any> | undefined) => {
  logApiCall(apiUri);
  return client.post(apiUri, data, config)
  .then(res => {
    // print("response: ", res.headers);
    if (res.headers[JWT_ID.toLowerCase()]) {
      localStorage.setItem(JWT_ID, res.headers[JWT_ID.toLowerCase()]);
    }
    if (res.headers[REMEMBER_ME_ID.toLowerCase()]) {
      localStorage.setItem(REMEMBER_ME_ID, res.headers[REMEMBER_ME_ID.toLowerCase()]);
    }
    if (res.headers["oid"]) {
      // otp can only stored in memory
      OTP_ID = res.headers["oid"]
    }
    return res;
  })
  .catch(async (error) => {
    const retryAble = (apiUri === "/login" || apiUri === "/logout") ? false : await errorHandler(error);
    if (retryAble) {
      return client.post(apiUri, data, config);
    } else {
      let errorResponse;
      try {
        try {
          errorResponse = ExceptionResponse.decode(Buffer.from(error.response.data));
          logApiError(apiUri, errorResponse.message);
          print("server error decoded: ", errorResponse);
        } catch (_) {
          errorResponse = ExceptionResponse.create();
          errorResponse.message = error.response.data.slice(error.response.data.indexOf("["))
          errorResponse.code = extractFirstBracketContent(errorResponse.message);
          logApiError(apiUri, errorResponse.message);
          print("server error parsed: ", errorResponse);
        }
      } catch (e) {
        print("unknown error: ", e);
        print("network error: ", error);
        logApiError(apiUri, "encountered: " + error.toString() + " while handing: " + e);
        throw error;
      }
      throw errorResponse;
    }
  });
}

function extractFirstBracketContent(input: string): string {
  const match = input.match(/\[([^\]]*)]/);
  return match ? match[1] : "";
}

const objectIdToDate = (objectId: string) => {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

// 防抖: 在事件被触发n秒后再执行回调，如果在这n秒内又被触发，则重新计时。
function debounce<T extends (...args: any) => any>(func: T, wait = DEFAULT_CACHE_TIME) {
  return lodash_debounce(func, wait, {
    // leading: true, // 先执行
    // trailing: false // 忽略n秒内的其他触发, 其他触发会重新计时 返回undefined
  });
}

// 节流: 规定一个单位时间，在这个单位时间内，只能有一次触发事件的回调函数执行, 其余函数返回缓存值
// 用于限制函数的调用频率. 一般用于修改后台数据的API，防止用户频繁调用。但对于有重试机制的API会导致重试失败，要特别注意
function throttle<T extends (...args: any) => any>(func: T, wait = DEFAULT_CACHE_TIME) {
  return lodash_throttle(func, wait, {
    leading: true, // 先执行
    trailing: false // 忽略n秒内的其他触发, 其他触发会重新计时 返回undefined
  });
}


// 防抖和节流的最大区别是, 防抖会将连续的触发合并成一次, 如果10秒内一直连续触发, 那么函数只会实际执行一次(后面的结果都是第一次的缓存)
// 节流则是 如果10秒内一直连续触发, 那么函数只会实际执行多次, 但是每次执行的间隔时间是固定的

// 需要注意，这里的防抖和节流不会考虑函数参数不同的情况。这可能会导致在高频率调用时，函数参数不同的情况下，会导致函数执行的结果不符合预期。应当根据实际情况进行调整频率和参数的设计。

export {debounce, throttle, captchaUrl, toApiUri, apiPost, objectIdToDate};