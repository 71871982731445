// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/storage/StorageMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {PageResult} from "../QueryMessage";
import {CustomerProfile} from "../customer/CustomerInfo";

export const protobufPackage = "proto.framework";

export interface UploadUrlRequest {
  contentType: string;
  contentSizeInBytes: number;
  /** name of the file, used for download Content-disposition */
  name: string;
  /** how many parts the file will be split into, default is 1 */
  partNumber: number;
}

export interface UploadUrlResponse {
  /** the id of the file */
  id: string;
  /** a list of urls, each url is for a part of the file */
  url: string[];
}

export interface CommitUploadRequest {
  /** the id of the file */
  id: string;
  isPublic: boolean;
  etag: string[];
}

export interface FileMetaData {
  id: string;
  owner: CustomerProfile | undefined;
  name: string;
  contentType: string;
  sizeInBytes: number;
  isPublic: boolean;
  url: string;
  /** timestamp (in miliseconds) when the file is created */
  createTime: number;
}

export interface FileQueryResponse {
  pageResult: PageResult | undefined;
  files: FileMetaData[];
}

function createBaseUploadUrlRequest(): UploadUrlRequest {
  return { contentType: "", contentSizeInBytes: 0, name: "", partNumber: 0 };
}

export const UploadUrlRequest = {
  encode(message: UploadUrlRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.contentType !== "") {
      writer.uint32(10).string(message.contentType);
    }
    if (message.contentSizeInBytes !== 0) {
      writer.uint32(16).int64(message.contentSizeInBytes);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.partNumber !== 0) {
      writer.uint32(32).int32(message.partNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadUrlRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadUrlRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contentType = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contentSizeInBytes = longToNumber(reader.int64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.partNumber = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadUrlRequest {
    return {
      contentType: isSet(object.contentType) ? globalThis.String(object.contentType) : "",
      contentSizeInBytes: isSet(object.contentSizeInBytes) ? globalThis.Number(object.contentSizeInBytes) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      partNumber: isSet(object.partNumber) ? globalThis.Number(object.partNumber) : 0,
    };
  },

  toJSON(message: UploadUrlRequest): unknown {
    const obj: any = {};
    if (message.contentType !== "") {
      obj.contentType = message.contentType;
    }
    if (message.contentSizeInBytes !== 0) {
      obj.contentSizeInBytes = Math.round(message.contentSizeInBytes);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.partNumber !== 0) {
      obj.partNumber = Math.round(message.partNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadUrlRequest>, I>>(base?: I): UploadUrlRequest {
    return UploadUrlRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadUrlRequest>, I>>(object: I): UploadUrlRequest {
    const message = createBaseUploadUrlRequest();
    message.contentType = object.contentType ?? "";
    message.contentSizeInBytes = object.contentSizeInBytes ?? 0;
    message.name = object.name ?? "";
    message.partNumber = object.partNumber ?? 0;
    return message;
  },
};

function createBaseUploadUrlResponse(): UploadUrlResponse {
  return { id: "", url: [] };
}

export const UploadUrlResponse = {
  encode(message: UploadUrlResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.url) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadUrlResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadUrlResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadUrlResponse {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      url: globalThis.Array.isArray(object?.url) ? object.url.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: UploadUrlResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.url?.length) {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadUrlResponse>, I>>(base?: I): UploadUrlResponse {
    return UploadUrlResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadUrlResponse>, I>>(object: I): UploadUrlResponse {
    const message = createBaseUploadUrlResponse();
    message.id = object.id ?? "";
    message.url = object.url?.map((e) => e) || [];
    return message;
  },
};

function createBaseCommitUploadRequest(): CommitUploadRequest {
  return { id: "", isPublic: false, etag: [] };
}

export const CommitUploadRequest = {
  encode(message: CommitUploadRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.isPublic !== false) {
      writer.uint32(16).bool(message.isPublic);
    }
    for (const v of message.etag) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CommitUploadRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommitUploadRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.etag.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommitUploadRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      isPublic: isSet(object.isPublic) ? globalThis.Boolean(object.isPublic) : false,
      etag: globalThis.Array.isArray(object?.etag) ? object.etag.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: CommitUploadRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.isPublic !== false) {
      obj.isPublic = message.isPublic;
    }
    if (message.etag?.length) {
      obj.etag = message.etag;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommitUploadRequest>, I>>(base?: I): CommitUploadRequest {
    return CommitUploadRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommitUploadRequest>, I>>(object: I): CommitUploadRequest {
    const message = createBaseCommitUploadRequest();
    message.id = object.id ?? "";
    message.isPublic = object.isPublic ?? false;
    message.etag = object.etag?.map((e) => e) || [];
    return message;
  },
};

function createBaseFileMetaData(): FileMetaData {
  return {
    id: "",
    owner: undefined,
    name: "",
    contentType: "",
    sizeInBytes: 0,
    isPublic: false,
    url: "",
    createTime: 0,
  };
}

export const FileMetaData = {
  encode(message: FileMetaData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.owner !== undefined) {
      CustomerProfile.encode(message.owner, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.contentType !== "") {
      writer.uint32(34).string(message.contentType);
    }
    if (message.sizeInBytes !== 0) {
      writer.uint32(40).int64(message.sizeInBytes);
    }
    if (message.isPublic !== false) {
      writer.uint32(48).bool(message.isPublic);
    }
    if (message.url !== "") {
      writer.uint32(58).string(message.url);
    }
    if (message.createTime !== 0) {
      writer.uint32(64).int64(message.createTime);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileMetaData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileMetaData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.owner = CustomerProfile.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.contentType = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sizeInBytes = longToNumber(reader.int64());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.url = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.createTime = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileMetaData {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      owner: isSet(object.owner) ? CustomerProfile.fromJSON(object.owner) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      contentType: isSet(object.contentType) ? globalThis.String(object.contentType) : "",
      sizeInBytes: isSet(object.sizeInBytes) ? globalThis.Number(object.sizeInBytes) : 0,
      isPublic: isSet(object.isPublic) ? globalThis.Boolean(object.isPublic) : false,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      createTime: isSet(object.createTime) ? globalThis.Number(object.createTime) : 0,
    };
  },

  toJSON(message: FileMetaData): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.owner !== undefined) {
      obj.owner = CustomerProfile.toJSON(message.owner);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.contentType !== "") {
      obj.contentType = message.contentType;
    }
    if (message.sizeInBytes !== 0) {
      obj.sizeInBytes = Math.round(message.sizeInBytes);
    }
    if (message.isPublic !== false) {
      obj.isPublic = message.isPublic;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.createTime !== 0) {
      obj.createTime = Math.round(message.createTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileMetaData>, I>>(base?: I): FileMetaData {
    return FileMetaData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileMetaData>, I>>(object: I): FileMetaData {
    const message = createBaseFileMetaData();
    message.id = object.id ?? "";
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? CustomerProfile.fromPartial(object.owner)
      : undefined;
    message.name = object.name ?? "";
    message.contentType = object.contentType ?? "";
    message.sizeInBytes = object.sizeInBytes ?? 0;
    message.isPublic = object.isPublic ?? false;
    message.url = object.url ?? "";
    message.createTime = object.createTime ?? 0;
    return message;
  },
};

function createBaseFileQueryResponse(): FileQueryResponse {
  return { pageResult: undefined, files: [] };
}

export const FileQueryResponse = {
  encode(message: FileQueryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageResult !== undefined) {
      PageResult.encode(message.pageResult, writer.uint32(10).fork()).join();
    }
    for (const v of message.files) {
      FileMetaData.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileQueryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileQueryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageResult = PageResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.files.push(FileMetaData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileQueryResponse {
    return {
      pageResult: isSet(object.pageResult) ? PageResult.fromJSON(object.pageResult) : undefined,
      files: globalThis.Array.isArray(object?.files) ? object.files.map((e: any) => FileMetaData.fromJSON(e)) : [],
    };
  },

  toJSON(message: FileQueryResponse): unknown {
    const obj: any = {};
    if (message.pageResult !== undefined) {
      obj.pageResult = PageResult.toJSON(message.pageResult);
    }
    if (message.files?.length) {
      obj.files = message.files.map((e) => FileMetaData.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileQueryResponse>, I>>(base?: I): FileQueryResponse {
    return FileQueryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileQueryResponse>, I>>(object: I): FileQueryResponse {
    const message = createBaseFileQueryResponse();
    message.pageResult = (object.pageResult !== undefined && object.pageResult !== null)
      ? PageResult.fromPartial(object.pageResult)
      : undefined;
    message.files = object.files?.map((e) => FileMetaData.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
