// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/websocket/WebsocketMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";

export const protobufPackage = "proto.framework.websocket";

/** each channel must be defined here with documentation */
export enum SocketChannel {
  /** UNKNOWN - unknown channel, not set */
  UNKNOWN = 0,
  /**
   * SUBSCRIBE - 用于客户端订阅某个channel的消息, text为channel的number号
   * 为何不使用post请求: 为了验证websocket的连接是否正常, 通过订阅消息来验证，如果订阅成功，说明连接正常
   * 注意，只对广播消息起作用，一对一的消息不需要订阅 也无法取消订阅
   */
  SUBSCRIBE = 1,
  /** UNSUBSCRIBE - 用于客户端取消订阅某个channel的消息, text为channel的number号 */
  UNSUBSCRIBE = 2,
  /** SET_LOCALE - 用户客户端设置websocket使用的locale翻译 */
  SET_LOCALE = 3,
  /** SYSTEM_STATUS - 系统状态消息 channel 用于发送系统状态消息 比如当前在线人数等 内部测试信息等 */
  SYSTEM_STATUS = 4,
  /** CHAT - 用于给客户端发送聊天消息 */
  CHAT = 5,
  /** CLIENT_LOG - 客户端向服务器发送分析日志 */
  CLIENT_LOG = 6,
  /** NOTIFICATION - 用于发送通知消息 */
  NOTIFICATION = 7,
  UNRECOGNIZED = -1,
}

export function socketChannelFromJSON(object: any): SocketChannel {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return SocketChannel.UNKNOWN;
    case 1:
    case "SUBSCRIBE":
      return SocketChannel.SUBSCRIBE;
    case 2:
    case "UNSUBSCRIBE":
      return SocketChannel.UNSUBSCRIBE;
    case 3:
    case "SET_LOCALE":
      return SocketChannel.SET_LOCALE;
    case 4:
    case "SYSTEM_STATUS":
      return SocketChannel.SYSTEM_STATUS;
    case 5:
    case "CHAT":
      return SocketChannel.CHAT;
    case 6:
    case "CLIENT_LOG":
      return SocketChannel.CLIENT_LOG;
    case 7:
    case "NOTIFICATION":
      return SocketChannel.NOTIFICATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SocketChannel.UNRECOGNIZED;
  }
}

export function socketChannelToJSON(object: SocketChannel): string {
  switch (object) {
    case SocketChannel.UNKNOWN:
      return "UNKNOWN";
    case SocketChannel.SUBSCRIBE:
      return "SUBSCRIBE";
    case SocketChannel.UNSUBSCRIBE:
      return "UNSUBSCRIBE";
    case SocketChannel.SET_LOCALE:
      return "SET_LOCALE";
    case SocketChannel.SYSTEM_STATUS:
      return "SYSTEM_STATUS";
    case SocketChannel.CHAT:
      return "CHAT";
    case SocketChannel.CLIENT_LOG:
      return "CLIENT_LOG";
    case SocketChannel.NOTIFICATION:
      return "NOTIFICATION";
    case SocketChannel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WebsocketMessage {
  /** the channel of the message */
  channel: SocketChannel;
  /** the sub channel of the message */
  subChannel: string;
  /** the sender's userid, if not set, it is a system message */
  from: string;
  /** the receiver's userid, if not set, it is a message to system */
  to: string;
  /** the timestamp of the message, in miliseconds */
  timestamp: number;
  text?:
    | string
    | undefined;
  /** used internally for message translation */
  translatable?: Translatable | undefined;
  json?: string | undefined;
  binaryContent?: Uint8Array | undefined;
}

export interface Translatable {
  messageCode: string;
  args: string[];
}

export interface ClientAction {
  /** the timestamp of the action on client side, in miliseconds */
  timestamp: number;
  /** suggested format: [type].[location].[action], but can be any string */
  action: string;
}

function createBaseWebsocketMessage(): WebsocketMessage {
  return {
    channel: 0,
    subChannel: "",
    from: "",
    to: "",
    timestamp: 0,
    text: undefined,
    translatable: undefined,
    json: undefined,
    binaryContent: undefined,
  };
}

export const WebsocketMessage = {
  encode(message: WebsocketMessage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.channel !== 0) {
      writer.uint32(8).int32(message.channel);
    }
    if (message.subChannel !== "") {
      writer.uint32(18).string(message.subChannel);
    }
    if (message.from !== "") {
      writer.uint32(26).string(message.from);
    }
    if (message.to !== "") {
      writer.uint32(34).string(message.to);
    }
    if (message.timestamp !== 0) {
      writer.uint32(40).int64(message.timestamp);
    }
    if (message.text !== undefined) {
      writer.uint32(58).string(message.text);
    }
    if (message.translatable !== undefined) {
      Translatable.encode(message.translatable, writer.uint32(66).fork()).join();
    }
    if (message.json !== undefined) {
      writer.uint32(74).string(message.json);
    }
    if (message.binaryContent !== undefined) {
      writer.uint32(82).bytes(message.binaryContent);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WebsocketMessage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebsocketMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.channel = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subChannel = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.from = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.to = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.timestamp = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.text = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.translatable = Translatable.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.json = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.binaryContent = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebsocketMessage {
    return {
      channel: isSet(object.channel) ? socketChannelFromJSON(object.channel) : 0,
      subChannel: isSet(object.subChannel) ? globalThis.String(object.subChannel) : "",
      from: isSet(object.from) ? globalThis.String(object.from) : "",
      to: isSet(object.to) ? globalThis.String(object.to) : "",
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
      text: isSet(object.text) ? globalThis.String(object.text) : undefined,
      translatable: isSet(object.translatable) ? Translatable.fromJSON(object.translatable) : undefined,
      json: isSet(object.json) ? globalThis.String(object.json) : undefined,
      binaryContent: isSet(object.binaryContent) ? bytesFromBase64(object.binaryContent) : undefined,
    };
  },

  toJSON(message: WebsocketMessage): unknown {
    const obj: any = {};
    if (message.channel !== 0) {
      obj.channel = socketChannelToJSON(message.channel);
    }
    if (message.subChannel !== "") {
      obj.subChannel = message.subChannel;
    }
    if (message.from !== "") {
      obj.from = message.from;
    }
    if (message.to !== "") {
      obj.to = message.to;
    }
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.text !== undefined) {
      obj.text = message.text;
    }
    if (message.translatable !== undefined) {
      obj.translatable = Translatable.toJSON(message.translatable);
    }
    if (message.json !== undefined) {
      obj.json = message.json;
    }
    if (message.binaryContent !== undefined) {
      obj.binaryContent = base64FromBytes(message.binaryContent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebsocketMessage>, I>>(base?: I): WebsocketMessage {
    return WebsocketMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebsocketMessage>, I>>(object: I): WebsocketMessage {
    const message = createBaseWebsocketMessage();
    message.channel = object.channel ?? 0;
    message.subChannel = object.subChannel ?? "";
    message.from = object.from ?? "";
    message.to = object.to ?? "";
    message.timestamp = object.timestamp ?? 0;
    message.text = object.text ?? undefined;
    message.translatable = (object.translatable !== undefined && object.translatable !== null)
      ? Translatable.fromPartial(object.translatable)
      : undefined;
    message.json = object.json ?? undefined;
    message.binaryContent = object.binaryContent ?? undefined;
    return message;
  },
};

function createBaseTranslatable(): Translatable {
  return { messageCode: "", args: [] };
}

export const Translatable = {
  encode(message: Translatable, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.messageCode !== "") {
      writer.uint32(10).string(message.messageCode);
    }
    for (const v of message.args) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Translatable {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTranslatable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messageCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.args.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Translatable {
    return {
      messageCode: isSet(object.messageCode) ? globalThis.String(object.messageCode) : "",
      args: globalThis.Array.isArray(object?.args) ? object.args.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Translatable): unknown {
    const obj: any = {};
    if (message.messageCode !== "") {
      obj.messageCode = message.messageCode;
    }
    if (message.args?.length) {
      obj.args = message.args;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Translatable>, I>>(base?: I): Translatable {
    return Translatable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Translatable>, I>>(object: I): Translatable {
    const message = createBaseTranslatable();
    message.messageCode = object.messageCode ?? "";
    message.args = object.args?.map((e) => e) || [];
    return message;
  },
};

function createBaseClientAction(): ClientAction {
  return { timestamp: 0, action: "" };
}

export const ClientAction = {
  encode(message: ClientAction, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestamp !== 0) {
      writer.uint32(8).int64(message.timestamp);
    }
    if (message.action !== "") {
      writer.uint32(18).string(message.action);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ClientAction {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestamp = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.action = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientAction {
    return {
      timestamp: isSet(object.timestamp) ? globalThis.Number(object.timestamp) : 0,
      action: isSet(object.action) ? globalThis.String(object.action) : "",
    };
  },

  toJSON(message: ClientAction): unknown {
    const obj: any = {};
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.action !== "") {
      obj.action = message.action;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientAction>, I>>(base?: I): ClientAction {
    return ClientAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientAction>, I>>(object: I): ClientAction {
    const message = createBaseClientAction();
    message.timestamp = object.timestamp ?? 0;
    message.action = object.action ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
