// import {apiPost, debounce, throttle} from "../util/MyDimensionClient";
import {pb_pbPost, str_pbPost} from "../../../api/util/MyDimensionClient";
import {TestMessageResponse} from "../../../proto/TestMessage";
import {debounce, throttle} from "lodash";
import {AuthenticationRequest} from "../../../proto/framework/SecurityMessage";

export const getProtoBuf = debounce(async (): Promise<TestMessageResponse> => {
  return str_pbPost("/index/proto_buf_get", TestMessageResponse);
});

export const getProtoBufResp = throttle(async (testMessage: AuthenticationRequest): Promise<TestMessageResponse> => {
  return pb_pbPost("/index/proto_buf", AuthenticationRequest, TestMessageResponse, testMessage);
}, 500, {leading: true, trailing: false});
