import {createTheme} from "@mui/material";

export const defaultTheme = createTheme(
    {
      typography: {
        fontFamily: ["Segoe UI", "Roboto", "Times New Roman", "cursive"].join(","),
        fontWeightLight: 350,
        fontWeightRegular: 450,
        fontWeightMedium: 550,
        fontWeightBold: 750,
        button: {
          textTransform: 'none',
          fontFamily: ["Roboto", "cursive", "Times New Roman"].join(","),
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
          fontWeightBold: 700,
          whiteSpace: "nowrap",
        },
      },
      components: {
        MuiTypography: {
          defaultProps: {
            component: "div",
          }
        },
        MuiDialog: {
          defaultProps: {
            maxWidth: 'sm',
            className: "blurDialog",
          }
        },
        MuiLink: {
          defaultProps: {
            underline: "hover",
            style: {
              cursor: "pointer",
            }
          }
        },
        MuiDialogContent: {
          defaultProps: {
            sx: {mx: "24px"}
          }
        },
        MuiDialogActions: {
          defaultProps: {
            sx: {marginBottom: "24px", mx: "36px"}
          }
        },
        MuiDialogContentText: {
          defaultProps: {
            component: "div",
          }
        }
      },
    }
);

export const cuteTheme = createTheme(
    {
      typography: {
        fontFamily: ["cursive", "Roboto", "Times New Roman"].join(","),
        button: {
          textTransform: 'none',
          fontFamily: ["Roboto", "cursive", "Times New Roman"].join(","),
        },

      }
    }
)