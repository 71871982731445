import React, {useCallback, useEffect, useState} from "react";
import {loadFull} from "tsparticles";
import Particles, {initParticlesEngine} from "@tsparticles/react";

export const Options = {
  Absorbers: {
    name: "Absorbers",
    particles: {
      number: {
        value: 10,
      },
      collisions: {
        enable: true,
      },
      color: {
        value: ["#4285F4", "#DB4437", "#F4B400", "#0F9D58"],
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: {
          min: 0.1,
          max: 1,
        },
      },
      size: {
        value: {
          min: 30,
          max: 60,
        },
      },
      move: {
        enable: true,
        speed: 1,
        direction: "top",
        straight: true,
        warp: true,
      },
    },
    interactivity: {
      // events: {
      //   onClick: {
      //     enable: true,
      //     mode: "push",
      //   },
      // },
      modes: {
        push: {
          quantity: 10,
        },
      },
    },
    // background: {
    //   color: "#fff",
    // },
  },
  Bubble: {
    fpsLimit: 120,
    particles: {
      color: {
        value: "random",
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.3,
      },
      size: {
        value: {min: 10, max: 30},
      },
      move: {
        angle: {
          offset: 0,
          value: 100,
        },
        enable: true,
        speed: 2,
        direction: "top",
        random: false,
        straight: false,
        outModes: {
          default: "destroy",
        },
      },
    },
    detectRetina: true,
    // background: {
    //   color: "#fff",
    // },
    emitters: [
      {
        // direction: "top",
        position: {
          y: 100,
        },
        life: {
          duration: 1,
          delay: 1,
          count: 0,
        },
      },
    ],
  },
  ConFetti: {
    fpsLimit: 120,
    particles: {
      number: {
        value: 100,
      },
      color: {
        value: ["#26ccff", "#a25afd", "#ff5e7e", "#88ff5a", "#fcff42", "#ffa62d", "#ff36ff"],
      },
      shape: {
        type: ["square", "circle"],
      },
      opacity: {
        value: {min: 0, max: 1},
        animation: {
          enable: true,
          speed: 0.5,
          startValue: "max",
          destroy: "min",
        },
      },
      size: {
        value: 5,
      },
      links: {
        enable: false,
      },
      life: {
        duration: {
          sync: true,
          value: 20 / 6,
        },
        count: 1,
      },
      move: {
        angle: {
          value: 45,
          offset: 0,
        },
        drift: 0,
        enable: true,
        gravity: {
          enable: true,
          acceleration: 9.81,
        },
        speed: 45,
        decay: 0.1,
        direction: -90,
        random: true,
        straight: false,
        outModes: {
          default: "none",
          bottom: "destroy",
        },
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: "random",
        animation: {
          enable: true,
          speed: 60,
        },
      },
      tilt: {
        direction: "random",
        enable: true,
        value: {
          min: 0,
          max: 360,
        },
        animation: {
          enable: true,
          speed: 60,
        },
      },
      roll: {
        darken: {
          enable: true,
          value: 25,
        },
        enable: true,
        speed: {
          min: 15,
          max: 25,
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        speed: {
          min: -15,
          max: 15,
        },
      },
    },
    detectRetina: true,
    motion: {
      disable: true,
    },
    emitters: {
      name: "confetti",
      startCount: 50,
      position: {
        random: true,
        // x: 50,
        y: -200,
      },
      size: {
        width: 0,
        height: 0,
      },
      rate: {
        delay: 0,
        quantity: 0.1,
      },
      life: {
        duration: 1,
        count: 100,
      },
    },
  },
  Fire: {
    fpsLimit: 120,
    particles: {
      number: {
        value: 200,
        density: {
          enable: true,
        },
      },
      color: {
        value: ["#fdcf58", "#757676", "#f27d0c", "#800909", "#f07f13"],
      },
      opacity: {
        value: {min: 0.1, max: 0.5},
      },
      size: {
        value: {min: 10, max: 60},
      },
      move: {
        enable: true,
        speed: 2,
        random: false,
      },
    },
    interactivity: {
      detectsOn: "window",
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
    },
    background: {
      image: "#fff"
    },
  },
  Fountain: {
    fpsLimit: 120,
    particles: {
      bounce: {
        vertical: {
          value: {
            min: 0.75,
            max: 0.85,
          },
        },
      },
      color: {
        value: ["#3998D0", "#2EB6AF", "#A9BD33", "#FEC73B", "#F89930", "#F45623", "#D62E32", "#EB586E", "#9952CF"],
      },
      number: {
        value: 0,
      },
      destroy: {
        mode: "split",
        split: {
          count: 2,
          factor: {
            value: {
              min: 1.1,
              max: 2,
            },
          },
          rate: {
            value: {
              min: 2,
              max: 3,
            },
          },
        },
      },

      // opacity: {
      //   animation: {
      //     enable: true,
      //     speed: 1,
      //     sync: false,
      //   },
      //   value: {min: 0, max: 1},
      // },

      opacity: {
        value: 0.5,
      },
      size: {
        value: {
          min: 10,
          max: 20,
        },
      },
      move: {
        enable: true,
        gravity: {
          enable: true,
          maxSpeed: 50,
        },
        speed: {
          min: 5,
          max: 10,
        },
        direction: "none",
        random: false,
        straight: false,
        outModes: {
          bottom: "split",
          default: "bounce",
          top: "none",
        },
        trail: {
          enable: false,
          fillColor: "#fff",
          length: 3,
        },
      },
    },
    detectRetina: true,
    background: {
      color: "#fff",
    },
    emitters: {
      direction: "top",
      life: {
        count: 0,
        duration: 0.15,
        delay: 3,
      },
      rate: {
        delay: 0.1,
        quantity: 5,
      },
      size: {
        width: 0,
        height: 0,
      },
    },
  },
  Snow: {
    // background: {
    //   color: "#333",
    // },
    particles: {
      color: {
        value: ["#d4f0fc", "#89d6fb", "#02a9f7", "#02577a", "#01303f"],
      },
      number: {
        value: 50,
      },
      move: {
        direction: "bottom",
        enable: true,
        random: false,
        straight: false,
      },
      opacity: {
        value: {min: 0.1, max: 0.5},
      },
      size: {
        value: {min: 1, max: 10},
      },
      wobble: {
        distance: 20,
        enable: true,
        speed: {
          min: -5,
          max: 5,
        },
      },
    },
  },
  Squares: {
    particles: {
      stroke: {
        width: 5,
        color: {
          value: [
            "#5bc0eb",
            "#fde74c",
            "#9bc53d",
            "#e55934",
            "#fa7921",
            "#2FF3E0",
            "#F8D210",
            "#FA26A0",
            "#F51720",
          ],
        },
      },
      shape: {
        type: "square",
        options: {
          square: {
            fill: false,
          },
        },
      },
      rotate: {
        value: 0,
        direction: "counterClockwise",
        animation: {
          enable: true,
          speed: 5,
          sync: true,
        },
      },
      size: {
        value: {min: 1, max: 500},
        animation: {
          enable: true,
          startValue: "min",
          speed: 160,
          sync: true,
          destroy: "max",
        },
      },
    },
    // background: {
    //   color: "#000",
    // },
    emitters: {
      position: {
        y: 50,
        x: 50,
      },
      rate: {
        delay: 2,
        quantity: 1,
      },
    },
  },
  Stars: {
    // background: {
    //   color: "#000",
    // },
    particles: {
      number: {
        value: 30,
      },
      color: {
        value: ["#ffd27d", "#ffa371", "#a6a8ff", "#fffa86", "#a87bff"],
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "out",
        },
        random: true,
        speed: 1,
        straight: false,
      },
      opacity: {
        animation: {
          enable: true,
          speed: 1,
          sync: false,
        },
        value: {min: 0, max: 1},
      },
      size: {
        value: {min: 10, max: 30},
      },
    },
  },
}

const getOptionals = (): any => {
  // Choose one Options based on time
  const date = new Date();
  const hour = date.getHours();
  if (hour >= 0 && hour < 3) {
    return Options.Stars;
  } else if (hour >= 3 && hour < 6) {
    return Options.Squares;
  } else if (hour >= 6 && hour < 9) {
    return Options.Snow;
  } else if (hour >= 9 && hour < 12) {
    return Options.ConFetti;
  } else if (hour >= 12 && hour < 15) {
    return Options.Fire;
  } else if (hour >= 15 && hour < 18) {
    return Options.Bubble;
  } else if (hour >= 18 && hour < 21) {
    return Options.Fountain;
  } else if (hour >= 21 && hour < 24) {
    return Options.Absorbers;
  }
}

const ParticleArea = ({id = "tsparticles", ...rest}) => {

  const [ init, setInit ] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      await loadFull(engine);
      // await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);


  const particlesLoaded = useCallback(async (container: any) => {
    // await console.log(container);
  }, []);

  return init ? <Particles
      id={id}
      // init={particlesInit}
      particlesLoaded={particlesLoaded}
      options={getOptionals()}
      {...rest}
  /> : <></>;
}

export default ParticleArea;