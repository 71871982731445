import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {disable as disableDarkMode, enable as enableDarkMode, setFetchMethod} from 'darkreader';
import {printDir} from "../../util/Logging";
import {LoginResponse} from "../../../proto/framework/customer/CustomerInfo";
import {UserRole} from "../../../proto/framework/SecurityMessage";

interface SettingState {
  enableParticle: boolean,
  enableAutoPlay: boolean,
  enableDarkMode: boolean,
  loginResponse: LoginResponse | null,
}

const initialState = {
  enableParticle: true,
  enableAutoPlay: false,
  enableDarkMode: false,
  loginResponse: null,
} as SettingState;

export const settingSlice = createSlice({
  name: "settingReducer",
  initialState,
  reducers: {
    setEnableParticle: (state, action: PayloadAction<boolean>) => {
      state.enableParticle = action.payload;
    },
    setEnableAutoPlay: (state, action: PayloadAction<boolean>) => {
      state.enableAutoPlay = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      setFetchMethod(window.fetch);
      // printDir("setDarkMode", action.payload);
      state.enableDarkMode = action.payload;
      if (action.payload) {
        try {
          enableDarkMode({
            brightness: 100,
            contrast: 90,
            sepia: 10
          });
        } catch (e) {
          printDir(e);
        }
      } else {
        try {
          disableDarkMode();
        } catch (e) {
          printDir(e);
        }
      }
    },
    setLoginResponse: (state, action: PayloadAction<LoginResponse | null>) => {
      state.loginResponse = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.loginResponse!.username = action.payload;
    },
    setCustomerName: (state, action: PayloadAction<string>) => {
      state.loginResponse!.name = action.payload;
    },
    setUserRole: (state, action: PayloadAction<UserRole>) => {
      state.loginResponse!.role = action.payload;
    },
    setAvatarUrl: (state, action: PayloadAction<string>) => {
      state.loginResponse!.avatarUrl = action.payload;
    }
  }
})

export const {setEnableParticle, setEnableAutoPlay, setDarkMode, setLoginResponse, setUsername,setCustomerName, setUserRole, setAvatarUrl} = settingSlice.actions;
export const selectEnableParticle = (state: RootState) => state.persistedSettingReducer.enableParticle;
export const selectEnableAutoPlay = (state: RootState) => state.persistedSettingReducer.enableAutoPlay;
export const selectEnableDarkMode = (state: RootState) => state.persistedSettingReducer.enableDarkMode;
export const selectLoginResponse = (state: RootState) => state.persistedSettingReducer.loginResponse;
export default settingSlice.reducer;