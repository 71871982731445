import {Alert, AlertTitle, Typography} from "../wrapper/MuiWrappers";
import {Stack, StackProps} from "@mui/material";
import {useAppSelector} from "../../api/redux/hooks";
import {selectLoginResponse} from "../../api/redux/slice/SettingSlice";
import AccountDropdown from "../../domain/account/component/AccountDropdown";

type RequireLoginInfoProps = {
  hide?: boolean;
} & StackProps;

const RequireLoginInfo = ({
                            hide = false,
                            children, sx, ...rest
                          }: RequireLoginInfoProps) => {
  const loginResponse = useAppSelector(selectLoginResponse);
  return <>
    {loginResponse === null ? !hide ?
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}
               spacing={2} sx={{marginTop: "64px", ...sx}} {...rest}>
          <Alert severity={"warning"} elevation={3}>
            <AlertTitle sx={{marginTop: "-10px", marginLeft: "-10px"}}><AccountDropdown flexGrow={1}
                                                                                        justifyContent={"start"}/></AlertTitle>
            <Typography textAlign={"start"}>
              {"You are trying to access a page that requires login. Please login or create an account first."}
            </Typography>
          </Alert>
        </Stack> : <div></div> : children}
  </>;
};


export default RequireLoginInfo;