import React from "react";
import {Stack} from "@mui/material";
import {Alert} from "../wrapper/MuiWrappers";

type DimensionFormProps = {
  children: React.ReactNode;
  elevation?: number;
  infoMessage?: string | undefined | React.ReactNode;
  errorMessage?: string | undefined;
}

const DimensionForm = ({
                         children,
                         elevation = 0,
                         infoMessage,
                         errorMessage,
                       }: DimensionFormProps) => {
  return <>
    {(infoMessage || errorMessage) &&
        <Stack direction={"column"} alignItems={"stretch"} spacing={1} marginTop={2}>
          {infoMessage &&
              <Alert severity={"info"}
                     elevation={elevation}>{infoMessage}</Alert>}
          {errorMessage && <Alert severity={"error"} elevation={elevation}>{errorMessage}</Alert>}
        </Stack>}

    <Stack direction={"column"} alignItems={"stretch"} spacing={4} marginTop={4}>
      {children}
    </Stack>

  </>;
}

export default DimensionForm;