import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Divider,
  useTheme
} from "@mui/material";
import GppGoodIcon from "@mui/icons-material/GppGood";
import InfoIcon from '@mui/icons-material/Info';
import Dialog from "@mui/material/Dialog";
import React, {useEffect} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Button, DialogTitle, LoadingButton} from "../wrapper/MuiWrappers";

// DimensionDialog 既可以配置到 DialogManager中方便全局调用，也可以直接作为子组件使用

export type DimensionDialogProps = {
  dialogTitle: string;
  primaryText?: string,
  isLoading?: boolean;
  titleSx?: React.CSSProperties;
  dialogStartNode?: React.ReactNode;
  dialogEndingNode?: React.ReactNode;
  onDialogClose: () => void;
  // 对话框提交时的返回值 用于判断loading状态 具体返回的东西不重要
  onDialogSubmit: () => Promise<void> | void;
  hideCancel?: boolean;
} & DialogProps;

const DimensionDialog = ({
                           children,
                           dialogTitle,
                           titleSx,
                           primaryText = "Submit",
                           isLoading = void 0,
                           dialogStartNode = <Divider><InfoIcon
                               className={"awesome-icon"}/></Divider>,
                           dialogEndingNode = <Divider sx={{marginTop: "16px"}}><GppGoodIcon
                               className={"awesome-icon"}/></Divider>,
                           onDialogClose,
                           onDialogSubmit,
                           hideCancel = false,
                           sx,
                           ...rest
                         }: DimensionDialogProps) => {
  // const dialogState = useAppSelector(selectDialogState);

  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const [initialHash, setInitialHash] = React.useState<string | undefined>(void 0);


  // https://github.com/mui/material-ui/issues/12759
  useEffect(() => {
    if (!rest.open) return;

    setInitialHash(window.location.hash);
    // print("initialHref",window.location.href);
    // print("initialHash",window.location.hash);
    const initHref = window.location.href;
    // const initHash = window.location.hash;


    window.onhashchange = () => {
      // e.preventDefault();
      // print("hashchange:" + initialUrl + initialHash);
      onCloseModal();
      window.location.href = initHref;
      // window.location.hash = initHash;
    }
    // window.location.hash += '#dialog';
    // window.history.pushState('forward', '', window.location.hash + '#dialog');
    // window.addEventListener('popstate', () => {
    //   print("popstate");
    //   onCloseModal();
    // });
    //
    return () => {
      // window.removeEventListener('popstate', onDialogClose);
      window.onhashchange = null;
    };
  }, [rest.open, onDialogClose]);

  const onCloseModal = () => {
    // print("onCloseModal");
    onDialogClose();
    window.onhashchange = null;
    if (initialHash) {
      window.location.hash = initialHash;
    }
    // print("initialHash on close", initialHash);
    // if (initialHash) {
    //   window.location.hash = initialHash;
    // }
    // window.history.pushState(
    //     'forward',
    //     '',
    //     window.location.pathname + window.location.search
    // );
  };


  const handleSubmit = () => {
    setLoading(true);
    const submitResult = onDialogSubmit();
    if (submitResult instanceof Promise) {
      submitResult.finally(() => {
        setLoading(false);
        window.location.hash = initialHash || '';
      });
    } else {
      setLoading(false);
      window.location.hash = initialHash || '';
    }
  }

  return (
      <Dialog fullScreen={fullScreen} onClose={onCloseModal} fullWidth
              maxWidth={'md'} {...rest}>
        <DialogTitle textAlign={"center"}
                     sx={titleSx}>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"center"}>
            {dialogStartNode}
          </DialogContentText>
          {children}
          {dialogEndingNode}
        </DialogContent>
        <DialogActions>
          {!hideCancel && <Button onClick={onCloseModal}>{"Cancel"}</Button>}
          <LoadingButton onClick={handleSubmit} variant={"contained"}
                         disableElevation
                         loading={isLoading === void 0 ? loading : isLoading}>{primaryText}</LoadingButton>
        </DialogActions>
      </Dialog>
  );
}

export default DimensionDialog;