import {in_prod} from "../applicationConfig";


export const print = in_prod ? (...message: any) => {
      return;
    } :
    (...message: any) => {
      if (message === undefined) {
        console.log("undefined");
        return;
      }
      for (const m of message) {
        console.dir(m);
      }
    }


export const println = in_prod ? (message?: any, ...optionalParams: any[]) => {
      return;
    } :
    (message?: any, ...optionalParams: any[]) => {
      console.log(message, ...optionalParams);
    }

export const printDir = in_prod ? (item?: any, options?: any) => {
      return;
    } :
    (item?: any, options?: any) => {
      console.dir(item, options);
    }