// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/application/driver/DriverMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {CustomerProfile} from "../../framework/customer/CustomerInfo";
import {FileMetaData} from "../../framework/storage/StorageMessage";

export const protobufPackage = "proto.framework";

export interface DriverFileProfile {
  id: string;
  path: string;
  name: string;
  isFolder: boolean;
  isPublic: boolean;
  owner: CustomerProfile | undefined;
  fileMetaData?: FileMetaData | undefined;
  url?: string | undefined;
  children: DriverFileProfile[];
  createdDate: number;
  lastModifiedDate: number;
}

export interface MutateDriverFileRequest {
  ids: string[];
  newPath: string;
  newName: string;
  newIsPublic?: boolean | undefined;
}

export interface QueryDriverFileRequest {
  id?: string | undefined;
  pathOwner?: QueryDriverFileRequest_PathOwnerRequest | undefined;
}

export interface QueryDriverFileRequest_PathOwnerRequest {
  /** the path of the file / folder, should be in /a/b/c format */
  path: string;
  ownerId: string;
}

export interface AddDriverFileRequest {
  path: string;
  name: string;
  isPublic: boolean;
  isFolder: boolean;
  objectId?: string | undefined;
  url?: string | undefined;
}

function createBaseDriverFileProfile(): DriverFileProfile {
  return {
    id: "",
    path: "",
    name: "",
    isFolder: false,
    isPublic: false,
    owner: undefined,
    fileMetaData: undefined,
    url: undefined,
    children: [],
    createdDate: 0,
    lastModifiedDate: 0,
  };
}

export const DriverFileProfile = {
  encode(message: DriverFileProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.path !== "") {
      writer.uint32(18).string(message.path);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.isFolder !== false) {
      writer.uint32(32).bool(message.isFolder);
    }
    if (message.isPublic !== false) {
      writer.uint32(40).bool(message.isPublic);
    }
    if (message.owner !== undefined) {
      CustomerProfile.encode(message.owner, writer.uint32(50).fork()).join();
    }
    if (message.fileMetaData !== undefined) {
      FileMetaData.encode(message.fileMetaData, writer.uint32(58).fork()).join();
    }
    if (message.url !== undefined) {
      writer.uint32(66).string(message.url);
    }
    for (const v of message.children) {
      DriverFileProfile.encode(v!, writer.uint32(74).fork()).join();
    }
    if (message.createdDate !== 0) {
      writer.uint32(80).int64(message.createdDate);
    }
    if (message.lastModifiedDate !== 0) {
      writer.uint32(88).int64(message.lastModifiedDate);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DriverFileProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDriverFileProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.path = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isFolder = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.owner = CustomerProfile.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fileMetaData = FileMetaData.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.url = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.children.push(DriverFileProfile.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.createdDate = longToNumber(reader.int64());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.lastModifiedDate = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DriverFileProfile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      isFolder: isSet(object.isFolder) ? globalThis.Boolean(object.isFolder) : false,
      isPublic: isSet(object.isPublic) ? globalThis.Boolean(object.isPublic) : false,
      owner: isSet(object.owner) ? CustomerProfile.fromJSON(object.owner) : undefined,
      fileMetaData: isSet(object.fileMetaData) ? FileMetaData.fromJSON(object.fileMetaData) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : undefined,
      children: globalThis.Array.isArray(object?.children)
        ? object.children.map((e: any) => DriverFileProfile.fromJSON(e))
        : [],
      createdDate: isSet(object.createdDate) ? globalThis.Number(object.createdDate) : 0,
      lastModifiedDate: isSet(object.lastModifiedDate) ? globalThis.Number(object.lastModifiedDate) : 0,
    };
  },

  toJSON(message: DriverFileProfile): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.isFolder !== false) {
      obj.isFolder = message.isFolder;
    }
    if (message.isPublic !== false) {
      obj.isPublic = message.isPublic;
    }
    if (message.owner !== undefined) {
      obj.owner = CustomerProfile.toJSON(message.owner);
    }
    if (message.fileMetaData !== undefined) {
      obj.fileMetaData = FileMetaData.toJSON(message.fileMetaData);
    }
    if (message.url !== undefined) {
      obj.url = message.url;
    }
    if (message.children?.length) {
      obj.children = message.children.map((e) => DriverFileProfile.toJSON(e));
    }
    if (message.createdDate !== 0) {
      obj.createdDate = Math.round(message.createdDate);
    }
    if (message.lastModifiedDate !== 0) {
      obj.lastModifiedDate = Math.round(message.lastModifiedDate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DriverFileProfile>, I>>(base?: I): DriverFileProfile {
    return DriverFileProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DriverFileProfile>, I>>(object: I): DriverFileProfile {
    const message = createBaseDriverFileProfile();
    message.id = object.id ?? "";
    message.path = object.path ?? "";
    message.name = object.name ?? "";
    message.isFolder = object.isFolder ?? false;
    message.isPublic = object.isPublic ?? false;
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? CustomerProfile.fromPartial(object.owner)
      : undefined;
    message.fileMetaData = (object.fileMetaData !== undefined && object.fileMetaData !== null)
      ? FileMetaData.fromPartial(object.fileMetaData)
      : undefined;
    message.url = object.url ?? undefined;
    message.children = object.children?.map((e) => DriverFileProfile.fromPartial(e)) || [];
    message.createdDate = object.createdDate ?? 0;
    message.lastModifiedDate = object.lastModifiedDate ?? 0;
    return message;
  },
};

function createBaseMutateDriverFileRequest(): MutateDriverFileRequest {
  return { ids: [], newPath: "", newName: "", newIsPublic: undefined };
}

export const MutateDriverFileRequest = {
  encode(message: MutateDriverFileRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.ids) {
      writer.uint32(10).string(v!);
    }
    if (message.newPath !== "") {
      writer.uint32(18).string(message.newPath);
    }
    if (message.newName !== "") {
      writer.uint32(26).string(message.newName);
    }
    if (message.newIsPublic !== undefined) {
      writer.uint32(32).bool(message.newIsPublic);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MutateDriverFileRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMutateDriverFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newPath = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.newName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.newIsPublic = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MutateDriverFileRequest {
    return {
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      newPath: isSet(object.newPath) ? globalThis.String(object.newPath) : "",
      newName: isSet(object.newName) ? globalThis.String(object.newName) : "",
      newIsPublic: isSet(object.newIsPublic) ? globalThis.Boolean(object.newIsPublic) : undefined,
    };
  },

  toJSON(message: MutateDriverFileRequest): unknown {
    const obj: any = {};
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.newPath !== "") {
      obj.newPath = message.newPath;
    }
    if (message.newName !== "") {
      obj.newName = message.newName;
    }
    if (message.newIsPublic !== undefined) {
      obj.newIsPublic = message.newIsPublic;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MutateDriverFileRequest>, I>>(base?: I): MutateDriverFileRequest {
    return MutateDriverFileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MutateDriverFileRequest>, I>>(object: I): MutateDriverFileRequest {
    const message = createBaseMutateDriverFileRequest();
    message.ids = object.ids?.map((e) => e) || [];
    message.newPath = object.newPath ?? "";
    message.newName = object.newName ?? "";
    message.newIsPublic = object.newIsPublic ?? undefined;
    return message;
  },
};

function createBaseQueryDriverFileRequest(): QueryDriverFileRequest {
  return { id: undefined, pathOwner: undefined };
}

export const QueryDriverFileRequest = {
  encode(message: QueryDriverFileRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.pathOwner !== undefined) {
      QueryDriverFileRequest_PathOwnerRequest.encode(message.pathOwner, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryDriverFileRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDriverFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pathOwner = QueryDriverFileRequest_PathOwnerRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryDriverFileRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : undefined,
      pathOwner: isSet(object.pathOwner)
        ? QueryDriverFileRequest_PathOwnerRequest.fromJSON(object.pathOwner)
        : undefined,
    };
  },

  toJSON(message: QueryDriverFileRequest): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = message.id;
    }
    if (message.pathOwner !== undefined) {
      obj.pathOwner = QueryDriverFileRequest_PathOwnerRequest.toJSON(message.pathOwner);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryDriverFileRequest>, I>>(base?: I): QueryDriverFileRequest {
    return QueryDriverFileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryDriverFileRequest>, I>>(object: I): QueryDriverFileRequest {
    const message = createBaseQueryDriverFileRequest();
    message.id = object.id ?? undefined;
    message.pathOwner = (object.pathOwner !== undefined && object.pathOwner !== null)
      ? QueryDriverFileRequest_PathOwnerRequest.fromPartial(object.pathOwner)
      : undefined;
    return message;
  },
};

function createBaseQueryDriverFileRequest_PathOwnerRequest(): QueryDriverFileRequest_PathOwnerRequest {
  return { path: "", ownerId: "" };
}

export const QueryDriverFileRequest_PathOwnerRequest = {
  encode(message: QueryDriverFileRequest_PathOwnerRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.ownerId !== "") {
      writer.uint32(18).string(message.ownerId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryDriverFileRequest_PathOwnerRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDriverFileRequest_PathOwnerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ownerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryDriverFileRequest_PathOwnerRequest {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      ownerId: isSet(object.ownerId) ? globalThis.String(object.ownerId) : "",
    };
  },

  toJSON(message: QueryDriverFileRequest_PathOwnerRequest): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.ownerId !== "") {
      obj.ownerId = message.ownerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryDriverFileRequest_PathOwnerRequest>, I>>(
    base?: I,
  ): QueryDriverFileRequest_PathOwnerRequest {
    return QueryDriverFileRequest_PathOwnerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryDriverFileRequest_PathOwnerRequest>, I>>(
    object: I,
  ): QueryDriverFileRequest_PathOwnerRequest {
    const message = createBaseQueryDriverFileRequest_PathOwnerRequest();
    message.path = object.path ?? "";
    message.ownerId = object.ownerId ?? "";
    return message;
  },
};

function createBaseAddDriverFileRequest(): AddDriverFileRequest {
  return { path: "", name: "", isPublic: false, isFolder: false, objectId: undefined, url: undefined };
}

export const AddDriverFileRequest = {
  encode(message: AddDriverFileRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.isPublic !== false) {
      writer.uint32(24).bool(message.isPublic);
    }
    if (message.isFolder !== false) {
      writer.uint32(32).bool(message.isFolder);
    }
    if (message.objectId !== undefined) {
      writer.uint32(42).string(message.objectId);
    }
    if (message.url !== undefined) {
      writer.uint32(50).string(message.url);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AddDriverFileRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddDriverFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isFolder = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddDriverFileRequest {
    return {
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      isPublic: isSet(object.isPublic) ? globalThis.Boolean(object.isPublic) : false,
      isFolder: isSet(object.isFolder) ? globalThis.Boolean(object.isFolder) : false,
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : undefined,
    };
  },

  toJSON(message: AddDriverFileRequest): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.isPublic !== false) {
      obj.isPublic = message.isPublic;
    }
    if (message.isFolder !== false) {
      obj.isFolder = message.isFolder;
    }
    if (message.objectId !== undefined) {
      obj.objectId = message.objectId;
    }
    if (message.url !== undefined) {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddDriverFileRequest>, I>>(base?: I): AddDriverFileRequest {
    return AddDriverFileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddDriverFileRequest>, I>>(object: I): AddDriverFileRequest {
    const message = createBaseAddDriverFileRequest();
    message.path = object.path ?? "";
    message.name = object.name ?? "";
    message.isPublic = object.isPublic ?? false;
    message.isFolder = object.isFolder ?? false;
    message.objectId = object.objectId ?? undefined;
    message.url = object.url ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
