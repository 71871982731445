import React from 'react';
import {Snackbar, SnackbarProps} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {Alert} from "../component/wrapper/MuiWrappers";
import copy from 'copy-to-clipboard';
// import {DimensionResponse} from "../model/DimensionResponse.type";

const AlertContext = React.createContext({
  showAlert: (text?: string, color?: AlertColor): void => void 0,
});

type GlobalAlertProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  color: AlertColor,
  alertText: string
} & SnackbarProps

const GlobalAlert = ({
                       open,
                       setOpen,
                       color,
                       alertText, sx, ...rest
                     }: GlobalAlertProps) => {
  return (
      <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={open}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          sx={{...sx}}
          {...rest}
      >
        <div>
          {/*https://stackoverflow.com/questions/74862197/typeerror-cannot-read-properties-of-null-reading-scrolltop-material-ui*/}
          <Alert
              onClose={() => setOpen(false)}
              onClick={() => copy(alertText)}
              className={"hideScrollBar pointer"}
              title={"Click to copy"}
              severity={color}
              variant="filled"
              sx={{width: '100%',
                maxHeight: "50dvh"
              }}

          >
            {alertText}
          </Alert>
        </div>
      </Snackbar>
  );
};

export {GlobalAlert};
export default AlertContext;