import {createHashRouter} from "react-router-dom";
import Portal from "../domain/website/page/Portal";
import React, {Suspense} from "react";
import LoadingScreen from "../domain/website/page/LoadingScreen";
import IndexWidget from "../domain/index/IndexWidget";
import RequireLoginInfo from "../component/container/RequireLoginInfo";

import {
  account_path,
  ai_chat_path,
  customer_path,
  driver_path,
  gallery_path,
  get_root_url,
  policy_path,
  root_path,
} from "./routerUtil";
//
// const ShareHub = React.lazy(() => import("../../widget/sharehub/widget/ShareHub"));
// const CustomerHomePage = React.lazy(() => import("../../widget/userportal/CustomerHomePage"));
// const ShareHubObjectPage = React.lazy(() => import("../../widget/sharehub/widget/ShareHubObjectPage"));
// const MuiGallery = React.lazy(() => import("../MuiGallery"));
// const CoreGallery = React.lazy(() => import("../core/CoreGallery"));
// const ApiPlayGround = React.lazy(() => import("../ApiPlayGround"));
// const MessageCenter = React.lazy(() => import("../../widget/message/MessageCenter"));
// const ShareHubCollection = React.lazy(() => import("../../widget/sharehub/widget/ShareHubCollection"));
// const RequireLoginInfo = React.lazy(() => import("../../widget/userportal/RequireLoginInfo"));
// const UserPortal = React.lazy(() => import("../../widget/userportal/UserPortal"));
// const ChatRoom = React.lazy(() => import("../../widget/chatroom/ChatRoom"));
// const FileExplorer = React.lazy(() => import("../../widget/driver/FileExplorer"));
// const FileDownloadPage = React.lazy(() => import("../../widget/driver/FileDownloadPage"));
// const SearchPage = React.lazy(() => import("../../widget/search/SearchPage"));
// const ProgressBarBlog = React.lazy(() => import("../blog/ProgressBarBlog"));
const WebSitePolicy = React.lazy(() => import("../domain/website/page/WebSitePolicy"));
const NotFound = React.lazy(() => import("../domain/website/page/NotFound"));
const UserCredentialPanel = React.lazy(() => import("../domain/account/component/UserCredentialPanel"));
const CustomerProfileWidget = React.lazy(() => import("../domain/customer/CustomerProfileWidget"));
const AiChatRoom = React.lazy(() => import("../domain/aiChatroom/AiChatRoom"));
const DriverWidget = React.lazy(() => import("../domain/driver/DriverWidget"));
const GalleryWidgetProps = React.lazy(() => import("../domain/gallery/GalleryWidget"));

const routes = [
  {
    path: get_root_url(),
    element: <Portal/>,
    children: [
      {
        path: root_path,
        element: <Suspense fallback={<LoadingScreen/>}><IndexWidget/></Suspense>
      },
      {
        path: account_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><UserCredentialPanel/></RequireLoginInfo></Suspense>,
      },
      // {
      //   path: github_oauth_path,
      //   element: <Suspense fallback={<LoadingScreen/>}><GithubOauth/></Suspense>,
      // },
      {
        path: customer_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><CustomerProfileWidget/></Suspense>
      },
      // {
      //   path: storage_path,
      //   element: <Suspense fallback={
      //     <LoadingScreen/>}><RequireLoginInfo><StorageWidget/></RequireLoginInfo></Suspense>
      // },
      {
        path: ai_chat_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><AiChatRoom/></RequireLoginInfo></Suspense>
      },
      {
        path: driver_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><RequireLoginInfo><DriverWidget/></RequireLoginInfo></Suspense>
      },
      {
        path: gallery_path,
        element: <Suspense fallback={
          <LoadingScreen/>}><GalleryWidgetProps/></Suspense>
      },
      // {
      //   path: "",
      //   element: <Suspense fallback={<LoadingScreen/>}><ShareHub/></Suspense>
      // },
      // {
      //   path: "user",
      //   element: <Suspense fallback={<LoadingScreen/>}><CustomerHomePage/></Suspense>,
      // },
      // {
      //   path: "resource",
      //   element: <Suspense
      //       fallback={<LoadingScreen/>}><ShareHubObjectPage></ShareHubObjectPage></Suspense>,
      // },
      // {
      //   path: "muigallery",
      //   element: <Suspense fallback={<LoadingScreen/>}><MuiGallery/></Suspense>
      // },
      // {
      //   path: "coregallery",
      //   element: <Suspense fallback={<LoadingScreen/>}><CoreGallery/></Suspense>
      // },
      // {
      //   path: "apiservice",
      //   element: <Suspense fallback={<LoadingScreen/>}><ApiPlayGround/></Suspense>,
      // },
      // {
      //   path: "message",
      //   element: <Suspense fallback={<LoadingScreen/>}><MessageCenter/></Suspense>,
      // },
      // {
      //   path: "collection",
      //   element: <Suspense fallback={<LoadingScreen/>}><ShareHubCollection/></Suspense>
      // },
      // {
      //   path: "about_website",
      //   element: <AboutWebsite/>
      // },
      // {
      //   path: "about_team",
      //   element: <AboutTeam/>
      // },
      // {
      //   path: "userportal",
      //   element: <Suspense fallback={
      //     <LoadingScreen/>}><RequireLoginInfo><UserPortal/></RequireLoginInfo></Suspense>,
      // },
      // {
      //   path: "chatgpt",
      //   element: <Suspense
      //       fallback={<LoadingScreen/>}><RequireLoginInfo><ChatRoom/></RequireLoginInfo></Suspense>,
      // },
      // {
      //   path: "driver",
      //   element: <Suspense fallback={
      //     <LoadingScreen/>}><RequireLoginInfo><FileExplorer/></RequireLoginInfo></Suspense>,
      // },
      // {
      //   path: "file",
      //   element: <Suspense fallback={<LoadingScreen/>}>
      //     <RequireLoginInfo><FileDownloadPage/></RequireLoginInfo></Suspense>,
      // },
      // {
      //   path: "search",
      //   element: <Suspense fallback={
      //     <LoadingScreen/>}><RequireLoginInfo><SearchPage/></RequireLoginInfo></Suspense>,
      // },
      // {
      //   path: "techblog",
      //   element: <Suspense fallback={<LoadingScreen/>}><ProgressBarBlog/></Suspense>
      // },
      {
        path: policy_path,
        element: <Suspense fallback={<LoadingScreen/>}> <WebSitePolicy/></Suspense>
      },
      {
        path: "*",
        element: <Suspense fallback={<LoadingScreen/>}><NotFound/></Suspense>

      }
    ]
  },
  {
    path: "/*",
    element: <Suspense fallback={<LoadingScreen/>}><NotFound/></Suspense>
  },
] as ({
  path: string,
  element: JSX.Element,
  children: { name: string, path: string, element: JSX.Element }[]
} | { path: string, element: JSX.Element, children?: undefined })[];
// history模式路由
// const rootRouter = createBrowserRouter(routes);
// hash模式路由
const rootRouter = createHashRouter(routes);

export default rootRouter;
export {routes};