import ContentButton from "./ContentButton";
import {KeyboardArrowDown} from "@mui/icons-material";
import React, {useState} from "react";
import {Menu} from "@mui/material";
import {mButtonProps, MenuItem} from "../wrapper/MuiWrappers";
import ListComponent from "../container/ListComponent";

// menu button 是一个便捷实用的下拉菜单按钮组件，
// 它可以包含标题、详情和图标。方便制作各种按钮。你可以将子元素放入 menuItems 中，这些子元素将会显示在下拉菜单中。
export type MenuButtonProps = {
  title?: string,
  menuAlign?: "left" | "right",
  detail?: string,
  icon?: React.ReactNode;
  elevation?: number;
  menuItems: React.ReactElement[],
  stopPropagation?: boolean;
  onClickExtra?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & mButtonProps;

const MenuButton = ({
                      title = void 0,
                      menuAlign = "left",
                      detail = void 0,
                      icon = void 0,
                      elevation = 1,
                      menuItems,
                      stopPropagation = false,
                      onClickExtra = void 0,
                      sx,
                      ...rest
                    }: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return <>
    {
      title ? <ContentButton title={title} detail={detail} icon={icon} sx={{
            ...sx
          }} {...rest}
                             endIcon={<KeyboardArrowDown/>}
                             onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                               if (onClickExtra) {
                                 onClickExtra(event);
                               }
                               if (stopPropagation) {
                                 event.stopPropagation();
                               }
                               setAnchorEl(event.currentTarget);
                             }}>
          </ContentButton> :
          <ContentButton icon={icon} detail={detail} sx={{
            margin: 0,
            padding: 0, ...sx
          }} {...rest}
                         onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                           if (onClickExtra) {
                             onClickExtra(event);
                           }
                           if (stopPropagation) {
                             event.stopPropagation();
                           }
                           setAnchorEl(event.currentTarget);
                         }}>
          </ContentButton>
    }
    <Menu anchorEl={anchorEl} open={menuOpen} aria-labelledby={"resource-button"}
          elevation={elevation}
          anchorOrigin={{vertical: 'bottom', horizontal: menuAlign}}
          transformOrigin={{vertical: 'top', horizontal: menuAlign}} onClose={() => {
      setAnchorEl(null);
    }}>
      <ListComponent data={menuItems}
                     renderItem={(item, index) => {
                       return <MenuItem
                           sx={{padding: 0, margin: 0}}
                           key={index} onClick={(event) => {
                         if (stopPropagation) {
                           event.stopPropagation();
                         }
                         setAnchorEl(null);
                         // if (item.props.onClick) {
                         //   item.props.onClick(event);
                         // }
                         // if (item.type === ContentButton) {
                         //   setAnchorEl(null);
                         // }
                       }}
                       >{item}</MenuItem>
                     }}
      />
    </Menu>
  </>
      ;
}

export default MenuButton;