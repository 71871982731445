import {Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import {FormControlLabel} from "../../../component/wrapper/MuiWrappers";

type FeatureSwitcherProps = {
  featureName: string;
  featureValue: boolean;
  setFeatureValue: (featureValue: boolean) => void;
};

const FeatureSwitcher = ({featureName, featureValue, setFeatureValue}: FeatureSwitcherProps) => {
  const {t} = useTranslation();

  const handleSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFeatureValue(event.target.checked);
    setChecked(event.target.checked);
  }, []);

  const [checked, setChecked] = React.useState(featureValue);

  return <FormControlLabel control={<Switch checked={checked} onChange={handleSwitch}/>}
                           label={t(featureName)}/>
}

export default FeatureSwitcher;