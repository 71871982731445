import {
  Alert as MUI_Alert,
  AlertTitle as MUI_AlertTitle,
  Button as MUI_Button,
  DialogTitle as MUI_DialogTitle,
  FormControlLabel as MUI_FormControlLabel,
  ListItemText as MUI_ListItemText,
  MenuItem as MUI_MenuItem,
  TextField as MUI_TextField,
  Tooltip as MUI_Tooltip,
  Typography as MUI_Typography,
} from "@mui/material";
import {AlertTitleProps, LoadingButton as MUI_LoadingButton} from "@mui/lab";
import {LoadingButtonProps} from "@mui/lab/LoadingButton";
import {TypographyProps} from "@mui/material/Typography";
import {ButtonProps} from "@mui/material/Button/Button";
import {MenuItemProps} from "@mui/material/MenuItem";
import {TextFieldProps} from "@mui/material/TextField";
import {AlertProps} from "@mui/material/Alert";
import {FormControlLabelProps} from "@mui/material/FormControlLabel";
import {TooltipProps} from "@mui/material/Tooltip";
import {DialogTitleProps} from "@mui/material/DialogTitle";
import {ListItemTextProps} from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";
import Linkify from "linkify-react";
import {Link} from "react-router-dom";
import {translateChildren} from "./wrapperUtil";


type mTypographyProps = {
  enableLinkify?: boolean,
  enableTranslate?: boolean
} & TypographyProps;

const Typography = ({
                      children,
                      enableLinkify = false,
                      enableTranslate = true,
                      sx,
                      ...rest
                    }: mTypographyProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);

  return (
      <MUI_Typography sx={{...sx}} {...rest}>
        {enableLinkify ? <Linkify options={{
              target: '_blank',
            }}>{translatedChildren}</Linkify> :
            translatedChildren}
      </MUI_Typography>
  );
}

export type mButtonProps = {
  enableTranslate?: boolean,
  to?: string,
  target?: string,
} & ButtonProps;

const Button = ({children, enableTranslate = true, to = void 0,target=void 0, sx, ...rest}: mButtonProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return <MUI_Button to={to} component={to ? Link : "button"} target={to ? target : void 0}
                     sx={{...sx}} {...rest}>{translatedChildren}</MUI_Button>;
}

type mLoadingButtonProps = {
  enableTranslate?: boolean,
  to?: string,
} & LoadingButtonProps;

const LoadingButton = ({
                         children,
                         enableTranslate = true,
                         to = void 0,
                         sx,
                         ...rest
                       }: mLoadingButtonProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return <MUI_LoadingButton to={to} component={to ? Link : "button"}
                            sx={{...sx}} {...rest}>
    {translatedChildren}
  </MUI_LoadingButton>

}

type mMenuItemProps = {
  enableTranslate?: boolean,
} & MenuItemProps;

const MenuItem = ({children, enableTranslate = true, sx, ...rest}: mMenuItemProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return <MUI_MenuItem
      sx={{...sx}} {...rest}>{translatedChildren}</MUI_MenuItem>;
}

type mListItemTextProps = {
  enableTranslate?: boolean,
} & ListItemTextProps;

const ListItemText  = ({children, enableTranslate = true, sx, ...rest}: mListItemTextProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return <MUI_ListItemText
      sx={{...sx}} {...rest}>{translatedChildren}</MUI_ListItemText>;
}


type mAlertProps = {
  enableTranslate?: boolean,
} & AlertProps;

const Alert = ({children, enableTranslate = true, sx, ...rest}: mAlertProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  const translatedTitle = translateChildren(rest.title, enableTranslate, t);
  return <MUI_Alert
      sx={{...sx}} {...rest} title={translatedTitle}>{translatedChildren}</MUI_Alert>;
}

type mAlertTitleProps = {
  enableTranslate?: boolean,
} & AlertTitleProps;

const AlertTitle = ({children, enableTranslate = true, sx, ...rest}: mAlertTitleProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return <MUI_AlertTitle
      sx={{...sx}} {...rest}>{translatedChildren}</MUI_AlertTitle>;
}

type mFormControlLabelProps = {
  enableTranslate?: boolean,
} & FormControlLabelProps;

const FormControlLabel = ({label, enableTranslate = true, sx, ...rest}: mFormControlLabelProps) => {
  const {t} = useTranslation();
  const translatedLabel = translateChildren(label, enableTranslate, t);
  return <MUI_FormControlLabel
      label={translatedLabel}
      sx={{...sx}} {...rest} />;
}

type mTooltipProps = {
  enableTranslate?: boolean,
} & TooltipProps;

const Tooltip = ({title, children, enableTranslate = true, sx, ...rest}: mTooltipProps) => {
  const {t} = useTranslation();
  const translatedTitle = typeof title === 'string' ? t(title) : title;
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return <MUI_Tooltip title={translatedTitle}
                      sx={{...sx}} {...rest}>{translatedChildren}</MUI_Tooltip>;
}

type mDialogTitleProps = {
  enableTranslate?: boolean,
} & DialogTitleProps;

const DialogTitle = ({children, enableTranslate = true, sx, ...rest}: mDialogTitleProps) => {
  const {t} = useTranslation();
  const translatedChildren = translateChildren(children, enableTranslate, t);
  return <MUI_DialogTitle
      sx={{...sx}} {...rest}>{translatedChildren}</MUI_DialogTitle>;
}

type mTextFieldProps = {
  enableTranslate?: boolean,
} & TextFieldProps;
const TextField = ({
                     enableTranslate = true,
                     label,
                     placeholder,
                     helperText,
                     sx,
                     ...rest
                   }: mTextFieldProps) => {
  const {t} = useTranslation();
  const translatedLabel = translateChildren(label, enableTranslate, t);
  const translatedPlaceholder = translateChildren(placeholder, enableTranslate, t);
  const translatedHelperText = translateChildren(helperText, enableTranslate, t);

  return <MUI_TextField
      label={translatedLabel}
      placeholder={translatedPlaceholder}
      helperText={translatedHelperText}
      sx={{...sx}} {...rest} />;
}

export {
  Typography,
  Button,
  MenuItem,
  Alert,
  AlertTitle,
  FormControlLabel,
  Tooltip,
  LoadingButton,
  DialogTitle,
  TextField,
  ListItemText
};