import Divider, {DividerProps} from "@mui/material/Divider";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import TabletIcon from "@mui/icons-material/Tablet";
import ComputerIcon from "@mui/icons-material/Computer";
import MonitorIcon from "@mui/icons-material/Monitor";
import React from "react";
import ListComponent from "../../../component/container/ListComponent";

type SmartDividerProps = {
  width?: string,
  iconXs?: React.ReactNode,
  iconSm?: React.ReactNode,
  iconMd?: React.ReactNode,
  iconLg?: React.ReactNode,
  iconXl?: React.ReactNode,
} & DividerProps;

const SmartDivider = ({
                        width = "80%",
                        iconXs = <PhoneIphoneIcon className={"awesome-icon"}/>,
                        iconSm = <StayCurrentPortraitIcon className={"awesome-icon"}/>,
                        iconMd = <TabletIcon className={"awesome-icon"}/>,
                        iconLg = <ComputerIcon className={"awesome-icon"}/>,
                        iconXl = <MonitorIcon className={"awesome-icon"}/>,
                        sx,
                        ...rest
                      }: SmartDividerProps) => {
  return <>
    <ListComponent data={[iconXs, iconSm, iconMd, iconLg, iconXl]} renderItem={(icon, index) => {
      return <Divider key={index} sx={{
        ...sx,
        width: width,
        display: {
          xs: index === 0 ? 'flex' : 'none',
          sm: index === 1 ? 'flex' : 'none',
          md: index === 2 ? 'flex' : 'none',
          lg: index === 3 ? 'flex' : 'none',
          xl: index === 4 ? 'flex' : 'none'
        }
      }} {...rest}>{icon}</Divider>
    }}/>
  </>
}

export default SmartDivider;
