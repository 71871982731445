import {Box, BoxProps, CircularProgress} from "@mui/material"
import {PAGE_MIN_HEIGHT} from "./Portal";

const LoadingScreen = ({sx, ...rest}: BoxProps) => {
  return <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={PAGE_MIN_HEIGHT}
      width="100%"
      sx={{
        backgroundColor: 'background.default',
        opacity: 0.7,
        ...sx
      }} // You can adjust the styling as needed
      {...rest}
  >
    <CircularProgress/>
  </Box>
}

export default LoadingScreen;