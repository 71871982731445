import {Fade, Menu} from "@mui/material";
import {useTranslation} from "react-i18next";
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import React from "react";
import {ButtonProps} from "@mui/material/Button/Button";
import {KeyboardArrowDown} from "@mui/icons-material";
import {Button, MenuItem} from "../../../component/wrapper/MuiWrappers";
import ListComponent from "../../../component/container/ListComponent";

const locales = {
  "en-US": {title: 'English'},
  "zh-CN": {title: '简体中文'},
};

type LanguageSwitcherProps = {
  menuAlign?: "left" | "right",
} & Omit<ButtonProps, "onClick" | "aria-controls" | "aria-haspopup" | "aria-expanded">;

const LanguageSwitcher = ({
                            menuAlign = "right",
                            variant = "text",
                            size = "medium",
                            color = "inherit",
                            startIcon = <TranslateOutlinedIcon/>,
                            endIcon = <KeyboardArrowDown/>,
                            ...rest
                          }: LanguageSwitcherProps) => {
  const {t, i18n} = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => {
      localStorage.setItem('language', lng);
      handleClose();
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null as EventTarget & HTMLButtonElement | null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <Button
        color={color}
        variant={variant}
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        {...rest}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
    >
      Language/语言
    </Button>
    <Menu
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: menuAlign}}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}>
      <ListComponent data={Object.keys(locales)} renderItem={(lng) => (
          <MenuItem key={lng} style={{minWidth: "160px"}}
                    onClick={() => changeLanguage(lng)}>{(locales as any)[lng].title}</MenuItem>)}/>
    </Menu>
  </>;
}

export default LanguageSwitcher;