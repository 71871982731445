import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Breakpoint} from "@mui/system/createTheme/";

type BreakpointContainerProps = {
  children: React.ReactNode;
  up?: Breakpoint | number; // included
  down?: Breakpoint | number // excluded
}

const BreakpointContainer = ({
                               up = 0,
                               down = 1000000,
                               children
                             }: BreakpointContainerProps) => {
  const theme = useTheme();
  const isUpMeet = useMediaQuery(theme.breakpoints.up(up));
  const isDownMeet = useMediaQuery(theme.breakpoints.down(down));
  return <>{isUpMeet && isDownMeet ? children : null}</>;

}

export default BreakpointContainer;