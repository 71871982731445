import React from "react";

type ListComponentProps<T> = {
  data: T[];
  renderItem: (item: T, index?:number) => React.ReactNode;
}

const ListComponent = <T extends any>({data, renderItem}: ListComponentProps<T>) => {
  return <>{data.map((item, index) => renderItem(item, index))}</>;
}

export default ListComponent;