import {DriverFileProfile} from "../../../proto/application/driver/DriverMessage";
import {get_driver_url} from "../../../navigation/routerUtil";

export const getReadableFileSize = (size?: number) => {
  if (size === undefined || size === null) {
    return "-";
  }
  if (size >= 1024 * 1024 * 1024) {
    return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  } else if (size >= 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  } else if (size >= 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else {
    return size + " B";
  }
}

export const getTotalSize = (files: DriverFileProfile[]) => {
  return files.reduce((acc, file) => {
    return acc + (file.fileMetaData?.sizeInBytes || 0);
  }, 0);
}


export const toDisplayTime = (milisecond: number) => {
  return new Date(milisecond).toLocaleString();

}

export const toVisibility = (isPublic: boolean) => {
  return isPublic ? "Public" : "Private";
}

export const getFileType = (fileItem: DriverFileProfile) => {
  if (fileItem.isFolder) {
    return "folder";
  }
  if (fileItem.url && fileItem.url !== "") {
    return "link";
  }
  if (fileItem.fileMetaData?.contentType) {
    if (fileItem.fileMetaData.contentType.includes("/")) {
      return fileItem.fileMetaData.contentType.split("/")[1];
    }
    if (fileItem.name.includes(".")) {
      const sep = fileItem.name.split(".");
      return sep[sep.length - 1];
    }
    return fileItem.fileMetaData.contentType;
  }
  if (fileItem.name.includes(".")) {
    const sep = fileItem.name.split(".");
    return sep[sep.length - 1];
  }
  return "file";

}

export const saveLinkAs = (url: string, filename: string) => {
  // Create a temporary anchor element
  const link = document.createElement('a');

  // Set the href to the provided URL
  link.href = url;

  // Set the download attribute to specify the file name
  link.download = filename;

  // Append the anchor to the document body (not visible to the user)
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the anchor element from the document
  document.body.removeChild(link);
}
export const getLink = (item: DriverFileProfile): string => {
  if (item.isFolder) {
    return get_driver_url(item.id);
  } else if (item.url && item.url !== "") {
    return item.url;
  } else {
    return item.fileMetaData?.url || "#";
  }
}

export const validateDriverFilename = (name: string) => {
  // length between 1 and 255
  if (name.length < 1 || name.length > 255) {
    return false;
  }
  // no / or \
  if (name.includes("/") || name.includes("\\")) {
    return false;
  }
  return true;
}