export const toColor = (seed: string): string => {
  if (seed.length === 0) {
    return "#000000"
  }
  const hash = seed.split("").reduce((acc, char) => {
    return acc + char.charCodeAt(0)
  }, 0)

  let colorString = hash.toString(16);
  if (colorString.length < 10) {
    colorString = colorString.padEnd(10, '0');
  }

  return `#${colorString.substring(2, 8)}`;
}

export const invertColor = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

const padZero = (str: string, len?: number): string => {
  len = len || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}