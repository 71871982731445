import {pb_pbPost, str_strPost, throttle, webHost} from "../../../api/util/MyDimensionClient";
import {AuthenticationRequest} from "../../../proto/framework/SecurityMessage";
import store from "../../../api/redux/store";
import {setLoginResponse, setUsername} from "../../../api/redux/slice/SettingSlice";
import {LoginResponse} from "../../../proto/framework/customer/CustomerInfo";

const signIn = throttle((authenticationRequest: AuthenticationRequest) => {
  return pb_pbPost("/login", AuthenticationRequest, LoginResponse, authenticationRequest, {
    headers: {
      "Otp": authenticationRequest.credential
    }
  }).then(
      (response) => {
        store.dispatch(setLoginResponse(response));
        return response;
      }
  );
});

const getGithubOAuthUrl = (rememberMe: boolean) => {
  // const redirectURI = webHost + "/" + github_oauth_path + "?rememberMe=" + rememberMe;
  const redirectURI = webHost + "?GithubRememberMe=" + rememberMe; // can only have one query parameter
  return getGithubClientID().then((clientId) => {
    return `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectURI}`;
  });
}

const getGithubClientID = () => {
  return str_strPost("/config/github_client_id");
}

const logout = throttle(() => {
  return str_strPost("/logout").then(
      (response) => {
        store.dispatch(setLoginResponse(null));
        return response;
      }
  );
});

const updateUsername = throttle((username: string) => {
  return str_strPost("/update_username", username).then(
      (response) => {
        store.dispatch(setUsername(username));
        return response;
      }
  );
});

const updatePassword = throttle((password: string) => {
  return str_strPost("/update_password", password);
});


// const signUp = throttle((authenticationRequest: AuthenticationRequest, captchaCode: string) => {
//   return apiPost("/login", AuthenticationRequest.encode(authenticationRequest).finish(), {
//     headers: {
//       "captcha": captchaCode
//     }
//   });
// })

export const SIGN_UP_RECAPTCHA_ACTION = "signup";

const signUp = throttle((authenticationRequest: AuthenticationRequest, recaptchaToken: string) => {
  return pb_pbPost("/login", AuthenticationRequest, LoginResponse, authenticationRequest, {
    headers: {
      "recaptcha-token": recaptchaToken,
      "recaptcha-action": SIGN_UP_RECAPTCHA_ACTION,
      "Otp": authenticationRequest.credential
    }
  }).then((response) => {
    store.dispatch(setLoginResponse(response));
    return response;
  });

  // return apiPost("/login", AuthenticationRequest.encode(authenticationRequest).finish(), {
  //   headers: {
  //     "recaptcha-token": recaptchaToken,
  //     "recaptcha-action": SIGN_UP_RECAPTCHA_ACTION
  //   }
  // }).then((response) => {
  //   store.dispatch(setLoginResponse(LoginResponse.decode(Buffer.from(response.data))));
  //   return response;
  // });
})

const requestEmailOtp = throttle((email: string, recaptchaToken: string) => {
  return str_strPost("/email_otp", email, {
    headers: {
      "recaptcha-token": recaptchaToken,
      "recaptcha-action": SIGN_UP_RECAPTCHA_ACTION
    }
  });
});

export {signIn, signUp, logout, requestEmailOtp, updateUsername, updatePassword, getGithubOAuthUrl}