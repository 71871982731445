import {pb_pbPost, pb_strPost, str_strPost} from "../../../api/util/MyDimensionClient";
import {
  AddDriverFileRequest,
  DriverFileProfile,
  MutateDriverFileRequest,
  QueryDriverFileRequest,
  QueryDriverFileRequest_PathOwnerRequest
} from "../../../proto/application/driver/DriverMessage";

const driverAPIPrefix = '/api/v0/driver';

const addFile = (fileName: string, filePath: string, isFolder = false, isPublic = false, objectId?: string, externalUrl?: string) => {
  const addFileRequest = AddDriverFileRequest.create();
  addFileRequest.name = fileName;
  addFileRequest.path = filePath;
  addFileRequest.isPublic = isPublic;
  addFileRequest.isFolder = isFolder;
  if (!isFolder) {
    if (objectId) {
      addFileRequest.objectId = objectId;
    }
    if (externalUrl) {
      addFileRequest.url = externalUrl;
    }
  }
  return pb_pbPost(driverAPIPrefix + "/add_file", AddDriverFileRequest, DriverFileProfile, addFileRequest);
}

export const addFolder = (name: string, path: string, isPublic = false) => {
  return addFile(name, path, true, isPublic);
}

export const addLinkFile = (name: string, path: string, url: string, isPublic = false) => {
  return addFile(name, path, false, isPublic, void 0, url);
}

export const addObjectFile = (name: string, path: string, objectId: string, isPublic = false) => {
  return addFile(name, path, false, isPublic, objectId);
}

const mutateFile = (fileIds: string[], newName?: string, newPath?: string, newVisibility?: boolean) => {
  const mutateFileRequest = MutateDriverFileRequest.create();
  mutateFileRequest.ids = fileIds;
  if (newName) {
    mutateFileRequest.newName = newName;
  }
  if (newPath) {
    mutateFileRequest.newPath = newPath;
  }
  if (newVisibility !== undefined) {
    mutateFileRequest.newIsPublic = newVisibility;
  }
  return pb_strPost(driverAPIPrefix + "/mutate_file", MutateDriverFileRequest, mutateFileRequest);
}
// newVisibility: undefined means no change
export const moveFile = (fileIds: string[], newPath: string, newVisibility?: boolean) => {
  return mutateFile(fileIds, void 0, newPath, newVisibility);
}
export const renameFile = (fileId: string, newName: string) => {
  return mutateFile([fileId], newName, void 0, void 0);
}
export const setVisibility = (fileId: string, isPublic: boolean) => {
  return mutateFile([fileId], void 0, void 0, isPublic);
}

export const deleteFile = (fileId: string | string[]) => {
  if (fileId instanceof Array) {
    if (fileId.length === 0) {
      return Promise.resolve();
    }
    return deleteFiles(fileId);
  }
  return str_strPost(driverAPIPrefix + "/delete_file", fileId);
}

export const deleteFiles = (fileIds: string[]) => {
  return str_strPost(driverAPIPrefix + "/delete_files", fileIds, {headers: {"Content-Type": "application/json"}});
}


export const getFile = (fileId?: string | null) => {
  const query = QueryDriverFileRequest.create();
  if (fileId !== undefined && fileId !== null && fileId !== "") {
    query.id = fileId;
  }
  return pb_pbPost(driverAPIPrefix + "/get_file", QueryDriverFileRequest, DriverFileProfile, query);
}

export const getFileId = (path: string, owner: string) => {
  const query = QueryDriverFileRequest.create();
  const pathAndOwner = QueryDriverFileRequest_PathOwnerRequest.create();
  pathAndOwner.path = path;
  pathAndOwner.ownerId = owner;
  query.pathOwner = pathAndOwner;
  return pb_strPost(driverAPIPrefix + "/get_file_id", QueryDriverFileRequest, query);
}

// path: /a/v/c
export const getFileByPathAndOwner = (path: string, owner: string) => {
  const query = QueryDriverFileRequest.create();
  const pathAndOwner = QueryDriverFileRequest_PathOwnerRequest.create();
  pathAndOwner.path = path;
  pathAndOwner.ownerId = owner;
  query.pathOwner = pathAndOwner;
  return pb_pbPost(driverAPIPrefix + "/get_file", QueryDriverFileRequest, DriverFileProfile, query);
}