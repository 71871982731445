import * as React from 'react';
import {useClientLog} from "../../../context/websocket/WebsocketHook";


export const ClientLogger = ({init=false, freq=3 * 1000}) => {
  useClientLog(init, freq);
  return (
      <>
      </>
  );
};