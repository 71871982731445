import * as React from 'react';
import {CloseableDialogProps, DialogNames} from "../../../../component/dialog/DialogManager";
import {useTheme} from "@mui/material";
import {
  useAutoFocus,
  useFieldValidation,
  useHandleDialogClose
} from "../../../../hook/CommonUiHook";
import {moveFile} from "../../service/DriverService";
import {useAppSelector} from "../../../../api/redux/hooks";
import {selectInitialData} from "../../../../api/redux/slice/DialogSlice";
import {ExceptionResponse} from "../../../../proto/framework/ExceptionMessage";
import DimensionDialog from "../../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DimensionForm from "../../../../component/container/DimensionForm";
import ClearableTextField from "../../../../component/input/ClearableTextField";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

export type MoveDriverFileDialogIType = {
  fileId: string[];
  currentPath: string;
}

export const MoveDriverFileDialog = React.memo(({
                                                  onDialogClose,
                                                  initialData,
                                                  open,
                                                  ...rest
                                                }: CloseableDialogProps<MoveDriverFileDialogIType>) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose(DialogNames.MoveDriverFileDialog, onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData);

  const fileId = initialData?.fileId || initialDataFromManager?.fileId || [];
  const currentPath = initialData?.currentPath || initialDataFromManager?.currentPath || "/";

  const [newPath, setNewPath, errorMessage, setErrorMessage, validate] = useFieldValidation((path) => {
    if (path.trim() === currentPath) {
      return "Path is the same as the current path";
    }

  }, currentPath);

  const inputRef = useAutoFocus([open], (inputRef) => {
    inputRef.current?.setSelectionRange(1, newPath.length);
  });


  const handleEditPath = async () => {
    if (!validate()) {
      return;
    }

    let normalizedPath = newPath.trim();
    if (normalizedPath.length === 0) {
      normalizedPath = "/";
    }
    if (!normalizedPath.endsWith("/")) {
      normalizedPath = normalizedPath + "/";
    }
    if (!normalizedPath.startsWith("/")) {
      normalizedPath = "/" + normalizedPath;
    }



    moveFile(fileId, normalizedPath).then(() => {
      handleClose(true);
    }).catch((error: ExceptionResponse) => {
      setErrorMessage(error.message);
    });
  }


  return <DimensionDialog
      dialogTitle={"Move File"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileMoveIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Move"}
      open={open}
      onDialogSubmit={handleEditPath}
      {...rest}>
    <DimensionForm
        infoMessage={"You can also drag and drop these files to the link of the target folder. Make sure the target path exists."}>
      <ClearableTextField value={newPath} setValue={setNewPath} label={"Target Path"}
                          onSubmit={handleEditPath} errorMessage={errorMessage}
                          inputRef={inputRef}/>
    </DimensionForm>
  </DimensionDialog>
});
