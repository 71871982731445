import {IconButton, InputAdornment, TextFieldProps} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, {forwardRef} from "react";
import {TextField} from "../wrapper/MuiWrappers";

export type ClearableTextFieldProps = {
  setValue: (value: string) => void,
  onSubmit: () => void,
  errorMessage?: string | undefined,
  startAdornment?: React.ReactNode,
  endAdornment?: React.ReactNode,
  clearAction?: () => void,
} & TextFieldProps;

const ClearableTextField = forwardRef(({
                                         value,
                                         setValue,
                                         label,
                                         placeholder,
                                         onSubmit,
                                         errorMessage = void 0,
                                         margin = "dense",
                                         type = "text",
                                         fullWidth = true,
                                         required = true,
                                         disabled = false,
                                         startAdornment = void 0,
                                         endAdornment = void 0,
                                         clearAction = void 0,
                                         ...rest
                                       }: ClearableTextFieldProps, ref) => {
  return <TextField autoFocus margin={margin} label={label} type={type}
                    fullWidth={fullWidth}
                    required={required}
                    value={value}
                    placeholder={placeholder}
                    error={errorMessage !== void 0}
                    helperText={errorMessage}
                    inputRef={ref}
                    onChange={(event) => {
                      setValue(event.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        onSubmit();
                      }
                    }}
                    InputProps={{ // <-- This is where the toggle button is added.
                      startAdornment: startAdornment &&
                          <InputAdornment position="start">
                            {startAdornment}
                          </InputAdornment>
                      ,
                      endAdornment: (endAdornment ? endAdornment :
                              <InputAdornment position="end">
                                <IconButton tabIndex={-1}
                                            disabled={disabled}
                                            onClick={() => {
                                              if (clearAction) {
                                                clearAction();
                                              } else {
                                                setValue("")
                                              }
                                            }}>
                                  <ClearIcon/></IconButton>
                              </InputAdornment>
                      )
                    }}
                    {...rest}
  />
});

export default ClearableTextField;