import * as React from 'react';
import {CloseableDialogProps, DialogNames} from "../../../../component/dialog/DialogManager";
import {useTheme} from "@mui/material";
import {
  useAutoFocus,
  useFieldsValidation,
  useHandleDialogClose
} from "../../../../hook/CommonUiHook";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {validateDriverFilename} from "../../util/Utils";
import {addLinkFile} from "../../service/DriverService";
import {useAppSelector} from "../../../../api/redux/hooks";
import {selectInitialData} from "../../../../api/redux/slice/DialogSlice";
import {ExceptionResponse} from "../../../../proto/framework/ExceptionMessage";
import DimensionDialog from "../../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DimensionForm from "../../../../component/container/DimensionForm";
import ClearableTextField from "../../../../component/input/ClearableTextField";
import {DriverFileProfile} from "../../../../proto/application/driver/DriverMessage";
import {validateUrl} from "../../../../api/util/Validation";

export type NewLinkFileDialogIType = {
  filePath: string;
  public: boolean;
}

export const NewLinkFileDialog = React.memo(({
                                    onDialogClose,
                                    initialData,
                                    open,
                                    ...rest
                                  }: CloseableDialogProps<NewLinkFileDialogIType>) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose<DriverFileProfile>(DialogNames.NewLinkFileDialog, onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData<NewLinkFileDialogIType>);

  const filePath = initialData?.filePath || initialDataFromManager?.filePath || "/";
  const filePublic = initialData?.public || initialDataFromManager?.public || false;

  const [newName, setNewName, nameError,
    newLink, setNewLink, linkError,
    fieldsErrorMessage, setFieldsErrorMessage, validate] = useFieldsValidation([
    (newName) => {
      if (newName.length === 0) {
        return "Filename cannot be empty";
      }
      if (!validateDriverFilename(newName)) {
        return "Filename is invalid";
      }
    },
    (newLink) => {
      if (newLink.length === 0) {
        return "Link cannot be empty";
      }
      if (!validateUrl(newLink)) {
        return "Link is invalid";
      }
    }], ([]) => void 0);


  const inputRef = useAutoFocus([open]);
  const handleCreate = async () => {
    if (!validate()) {
      return;
    }

    addLinkFile(newName, filePath, newLink, filePublic).then((response) => {
      handleClose(true, response);
    }).catch((error: ExceptionResponse) => {
      setFieldsErrorMessage(error.message);
    });
  }


  return <DimensionDialog
      dialogTitle={"New Link File"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><AttachFileIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Create"}
      open={open}
      onDialogSubmit={handleCreate}
      {...rest}>
    <DimensionForm
        infoMessage={"Create a File with External Link"}
        errorMessage={fieldsErrorMessage}>
      <ClearableTextField value={newName} setValue={setNewName} label={"Filename"}
                          onSubmit={handleCreate} errorMessage={nameError}
                          inputRef={inputRef}/>
      <ClearableTextField value={newLink} setValue={setNewLink} label={"Link"}
                          onSubmit={handleCreate} errorMessage={linkError}/>
    </DimensionForm>
  </DimensionDialog>
});
