import {TypographyProps} from "@mui/material/Typography";
import {Typography} from "./MuiWrappers";
import React from "react";

type DateDisplayProps = {
  date?: Date,
} & TypographyProps;

const DateDisplay = ({
                       date = new Date()
                       , ...rest
                     }: DateDisplayProps) => {
  return <Typography variant={"body2"} {...rest}>{date.toLocaleDateString()}</Typography>
}

export default React.memo(DateDisplay);