import React, {useState} from "react";
import {print, printDir} from "../../../api/util/Logging";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import {CloseableDialogProps, DialogNames} from "../../../component/dialog/DialogManager";
import {useHandleDialogClose} from "../../../hook/CommonUiHook";
import {AuthenticationRequest} from "../../../proto/framework/SecurityMessage";
import {getProtoBufResp} from "../service/DemoService";
import {TestMessageResponse} from "../../../proto/TestMessage";

const DemoDialog = ({open, onDialogClose = void 0, sx, ...rest}: CloseableDialogProps) => {


  const handleClose = useHandleDialogClose<{
    result?: TestMessageResponse,
    data?: string,
    error?: any
  }>(DialogNames.DemoDialog, onDialogClose);

  // Inner state for the dialog
  const [demoData, setDemoData] = useState("result");

  // Maintain the business logic of a concrete dialog inside the dialog component
  const onSubmit = () => {
    print("onSubmit");
    // call api
    const request = AuthenticationRequest.create();
    request.userIdentifier = "username";
    request.credential = "password";
    request.rememberMe = true;
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        getProtoBufResp(request).then((result) => {
          printDir(result.message);
          handleClose(true, {
            result: result,
            data: demoData
          });
          resolve();
        }).catch((error) => {
          // usually should show error instead of closing the dialog
          handleClose(false, {
            error: error,
          });
          resolve();
        });
      }, 2000)
    });

  }

  // UX/UI logic
  return <DimensionDialog
      dialogTitle={"Demo Dialog"}
      onDialogClose={() => handleClose(false)}
      onDialogSubmit={onSubmit}
      open={open}
      sx={sx}
      {...rest}
  >
    {"Hello World"}

  </DimensionDialog>;

}

export default React.memo(DemoDialog);