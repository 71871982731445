import imageCompression from 'browser-image-compression'; // 使用图片压缩库

export const compressImage = async (file: File, maxSizeMB = 0.1, maxWidthOrHeight = 1080, useJpeg = true) => {
  const options = {
    maxSizeMB: maxSizeMB,
    maxWidthOrHeight: maxWidthOrHeight,
    useWebWorker: true,
    fileType: useJpeg ? 'image/jpeg' : void 0,
  }
  return await imageCompression(file, options);
}