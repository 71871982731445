// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/SecurityMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";

export const protobufPackage = "proto.framework";

/**
 * Different authentication type will have different authentication strategy
 * Different token valid time, refresh strategy, etc
 */
export enum AuthenticationType {
  /** PASSWORD - Browser user name password */
  PASSWORD = 0,
  /** API_KEY - For non browser user, usually for API, */
  API_KEY = 1,
  EMAIL_OTP = 2,
  SMS_OTP = 3,
  /** ALLOW_LIST - For internal test/demo only, client side should not use this */
  ALLOW_LIST = 4,
  GOOGLE_OAUTH = 5,
  GITHUB_OAUTH = 6,
  UNRECOGNIZED = -1,
}

export function authenticationTypeFromJSON(object: any): AuthenticationType {
  switch (object) {
    case 0:
    case "PASSWORD":
      return AuthenticationType.PASSWORD;
    case 1:
    case "API_KEY":
      return AuthenticationType.API_KEY;
    case 2:
    case "EMAIL_OTP":
      return AuthenticationType.EMAIL_OTP;
    case 3:
    case "SMS_OTP":
      return AuthenticationType.SMS_OTP;
    case 4:
    case "ALLOW_LIST":
      return AuthenticationType.ALLOW_LIST;
    case 5:
    case "GOOGLE_OAUTH":
      return AuthenticationType.GOOGLE_OAUTH;
    case 6:
    case "GITHUB_OAUTH":
      return AuthenticationType.GITHUB_OAUTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AuthenticationType.UNRECOGNIZED;
  }
}

export function authenticationTypeToJSON(object: AuthenticationType): string {
  switch (object) {
    case AuthenticationType.PASSWORD:
      return "PASSWORD";
    case AuthenticationType.API_KEY:
      return "API_KEY";
    case AuthenticationType.EMAIL_OTP:
      return "EMAIL_OTP";
    case AuthenticationType.SMS_OTP:
      return "SMS_OTP";
    case AuthenticationType.ALLOW_LIST:
      return "ALLOW_LIST";
    case AuthenticationType.GOOGLE_OAUTH:
      return "GOOGLE_OAUTH";
    case AuthenticationType.GITHUB_OAUTH:
      return "GITHUB_OAUTH";
    case AuthenticationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum UserRole {
  /** ADMIN - 系统管理员 */
  ADMIN = 0,
  /** USER - 普通用户(已认证) */
  USER = 1,
  /** GUEST - 游客(未认证) */
  GUEST = 2,
  UNRECOGNIZED = -1,
}

export function userRoleFromJSON(object: any): UserRole {
  switch (object) {
    case 0:
    case "ADMIN":
      return UserRole.ADMIN;
    case 1:
    case "USER":
      return UserRole.USER;
    case 2:
    case "GUEST":
      return UserRole.GUEST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserRole.UNRECOGNIZED;
  }
}

export function userRoleToJSON(object: UserRole): string {
  switch (object) {
    case UserRole.ADMIN:
      return "ADMIN";
    case UserRole.USER:
      return "USER";
    case UserRole.GUEST:
      return "GUEST";
    case UserRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Authentication request message */
export interface AuthenticationRequest {
  /** An identifier for the user, could be username, email, phone number, api key, browser signature etc */
  userIdentifier: string;
  /** Credential, could be password, otp, etc */
  credential: string;
  /** Authentication type, it defines how to interpret the user_identifier and credential */
  authenticationType: AuthenticationType;
  /** Remember me, if true, the token will have a longer valid time or need a refresh token */
  rememberMe: boolean;
}

function createBaseAuthenticationRequest(): AuthenticationRequest {
  return { userIdentifier: "", credential: "", authenticationType: 0, rememberMe: false };
}

export const AuthenticationRequest = {
  encode(message: AuthenticationRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.userIdentifier !== "") {
      writer.uint32(10).string(message.userIdentifier);
    }
    if (message.credential !== "") {
      writer.uint32(18).string(message.credential);
    }
    if (message.authenticationType !== 0) {
      writer.uint32(24).int32(message.authenticationType);
    }
    if (message.rememberMe !== false) {
      writer.uint32(32).bool(message.rememberMe);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AuthenticationRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthenticationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.credential = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.authenticationType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.rememberMe = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthenticationRequest {
    return {
      userIdentifier: isSet(object.userIdentifier) ? globalThis.String(object.userIdentifier) : "",
      credential: isSet(object.credential) ? globalThis.String(object.credential) : "",
      authenticationType: isSet(object.authenticationType) ? authenticationTypeFromJSON(object.authenticationType) : 0,
      rememberMe: isSet(object.rememberMe) ? globalThis.Boolean(object.rememberMe) : false,
    };
  },

  toJSON(message: AuthenticationRequest): unknown {
    const obj: any = {};
    if (message.userIdentifier !== "") {
      obj.userIdentifier = message.userIdentifier;
    }
    if (message.credential !== "") {
      obj.credential = message.credential;
    }
    if (message.authenticationType !== 0) {
      obj.authenticationType = authenticationTypeToJSON(message.authenticationType);
    }
    if (message.rememberMe !== false) {
      obj.rememberMe = message.rememberMe;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthenticationRequest>, I>>(base?: I): AuthenticationRequest {
    return AuthenticationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthenticationRequest>, I>>(object: I): AuthenticationRequest {
    const message = createBaseAuthenticationRequest();
    message.userIdentifier = object.userIdentifier ?? "";
    message.credential = object.credential ?? "";
    message.authenticationType = object.authenticationType ?? 0;
    message.rememberMe = object.rememberMe ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
