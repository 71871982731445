import React, {useEffect} from 'react';
import {BoxProps} from "@mui/material";
import {selectEnableDarkMode, setDarkMode} from "../api/redux/slice/SettingSlice";
import {useAppDispatch, useAppSelector} from "../api/redux/hooks";
import {useWebSocketProvider, WebsocketContext} from "./websocket/WebsocketContext";

const GlobalProviderContainer = ({children}: BoxProps) => {
  const dispatch = useAppDispatch();
  const enableDarkMode = useAppSelector(selectEnableDarkMode);
  const {message, sendSocketMessage,subscribeTo, connected} = useWebSocketProvider();

  useEffect(() => {
    dispatch(setDarkMode(enableDarkMode));
  }, []);

  // return <>{children}</>;

  return <WebsocketContext.Provider value={
    {
      message: message,
      sendSocketMessage: sendSocketMessage,
      subscribeTo:subscribeTo,
      connected: connected,
    }
  }>
    {children}
  </WebsocketContext.Provider>;
}

export default GlobalProviderContainer;