import {IconButton, InputAdornment} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React from "react";
import ClearableTextField, {ClearableTextFieldProps} from "./ClearableTextField";

const PasswordTextField = ({
                             value,
                             setValue,
                             onSubmit,
                             label = "Password",
                             endAdornment = void 0,
                             ...rest
                           }: ClearableTextFieldProps) => {
  const [showPassword, setShowPassword] = React.useState(false);

  endAdornment = endAdornment ? <InputAdornment position="end">
    <IconButton
        tabIndex={-1}
        onClick={() => setShowPassword(!showPassword)}
    >
      {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
    </IconButton>
  </InputAdornment> : endAdornment;

  return <ClearableTextField value={value}
                             setValue={setValue}
                             onSubmit={onSubmit}
                             type={showPassword ? "text" : "password"}
                             label={label}
                             endAdornment={endAdornment}
                             {...rest} />
}

export default PasswordTextField;