import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../store'
import {CloseDialogCallback, DialogName} from "../../../component/dialog/DialogManager";

export interface DialogState<T = any> {
  dialogName: DialogName,
  // success: whether the dialog is finished / canceled
  // data: any data to be passed back to the caller
  onDialogClose: CloseDialogCallback,
  initialData?: T,
}

const initialState = {
  dialogName: "",
  onDialogClose: () => {
  },
  initialData: void 0
} as DialogState;

export const dialogSlice = createSlice({
  name: "dialogReducer",
  initialState,
  reducers: {
    invokeDialog: (state, action: PayloadAction<DialogState>) => {
      state.dialogName = action.payload.dialogName;
      state.initialData = action.payload.initialData || void 0;
      state.onDialogClose = (success, data) => {
        action.payload.onDialogClose(success, data);
      }
    }
  },
})


export const {invokeDialog} = dialogSlice.actions;
export const selectDialogName = (state: RootState) => state.dialogReducer.dialogName;
export const selectInitialData = <T=any>(state: RootState) => state.dialogReducer.initialData as T;
export const selectOnDialogClose = (state: RootState) => state.dialogReducer.onDialogClose;
export default dialogSlice.reducer;
