import React from "react";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import {CloseableDialogProps, DialogNames} from "../../../component/dialog/DialogManager";
import {useAutoFocus, useFieldsValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {validateEmail} from "../../../api/util/Validation";
import Divider from "@mui/material/Divider";
import LockIcon from '@mui/icons-material/Lock';
import DimensionForm from "../../../component/container/DimensionForm";
import ClearableTextField from "../../../component/input/ClearableTextField";
import PasswordTextField from "../../../component/input/PasswordTextField";
import {
  requestEmailOtp,
  SIGN_UP_RECAPTCHA_ACTION,
} from "../../account/service/AuthenticationService";
import {ExceptionResponse} from "../../../proto/framework/ExceptionMessage";
import {Button} from "../../../component/wrapper/MuiWrappers";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {print} from "../../../api/util/Logging";
import {setPrimaryEmail} from "../service/CustomerService";

type EditEmailDialogProps = {
  existingEmail?: string;
} & CloseableDialogProps;

const EditEmailDialog = ({
                           existingEmail = void 0,
                           open,
                           onDialogClose = void 0,
                           sx,
                           ...rest
                         }: EditEmailDialogProps) => {

  const handleClose = useHandleDialogClose<string>(DialogNames.EditEmailDialog, onDialogClose);
  const [otpSent, setOtpSent] = React.useState(false);
  const inputRef = useAutoFocus([open]);
  const [infoMessage, setInfoMessage] = React.useState("Cookie is used for persisting your login record.");

  const {executeRecaptcha} = useGoogleReCaptcha();


  const
      [email, setEmail, emailErrorMessage,
        otp, setOtp, otpErrorMessage,
        fieldsErrorMessage, setFieldsErrorMessage, validate] = useFieldsValidation([
            (email) => {
              if (email.length === 0) {
                return "Email cannot be empty";
              }
              if (existingEmail === email) {
                return "Email is the same as the existing email";
              }
              if (!validateEmail(email)) {
                return "Invalid Email Format";
              }
            },
            (otp) => {
              if (otp.length === 0) {
                return "OTP cannot be empty";
              }

            }],
          () => void 0);

  const onSubmit = () => {
    setFieldsErrorMessage(void 0);
    if (!validate()) {
      return;
    }

    return setPrimaryEmail(email, otp).then((response) => {
      handleClose(true, response.email);
    }).catch((error: ExceptionResponse) => {
      setFieldsErrorMessage(error.message);
      setOtpSent(false);
    });
  }

  const onRequestEmailOtp = async () => {
    setFieldsErrorMessage(void 0);
    setOtpSent(true);
    if (!validateEmail(email)) {
      setFieldsErrorMessage("Invalid Email Format");
      setOtpSent(false);
      return;
    }

    if (!executeRecaptcha) {
      print('Execute recaptcha not yet available');
      setFieldsErrorMessage("Something went wrong, please try again later");
      // use traditional captcha handling / skip
      setOtpSent(false);
      return;
    }

    // https://cloud.google.com/recaptcha/docs/actions-website
    const token = await executeRecaptcha(SIGN_UP_RECAPTCHA_ACTION);


    requestEmailOtp(email, token).then(() => {
      setInfoMessage("OTP has been sent to your email, please check it.");
      setOtpSent(true);
    }).catch(error => {
      setFieldsErrorMessage(error.message);
      setOtpSent(false);
    })

  }

  return <DimensionDialog
      dialogTitle={"Update Email"}
      dialogStartNode={<Divider><LockIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      onDialogSubmit={onSubmit}
      open={open}
      sx={sx}
      {...rest}
  >
    <DimensionForm infoMessage={infoMessage}
                   errorMessage={fieldsErrorMessage}>
      <ClearableTextField value={email} setValue={setEmail} label={"Username/Email"}
                          onSubmit={onSubmit} errorMessage={emailErrorMessage} disabled={otpSent}
                          inputRef={inputRef}/>
      <Button onClick={onRequestEmailOtp} variant={"contained"} disableElevation={true}
              disabled={otpSent}>{otpSent ? "Please check your email inbox" : "Send One Time Password To The Email"}</Button>

      <PasswordTextField value={otp} errorMessage={otpErrorMessage}
                         label={"One Time Password"}
                         onSubmit={onSubmit}
                         setValue={setOtp}/>
    </DimensionForm>

  </DimensionDialog>;
}

export default React.memo(EditEmailDialog);