import * as React from 'react';
import {CloseableDialogProps, DialogNames} from "../../../../component/dialog/DialogManager";
import {useTheme} from "@mui/material";
import {
  useAutoFocus,
  useFieldValidation,
  useHandleDialogClose
} from "../../../../hook/CommonUiHook";
import {validateDriverFilename} from "../../util/Utils";
import {renameFile} from "../../service/DriverService";
import {useAppSelector} from "../../../../api/redux/hooks";
import {selectInitialData} from "../../../../api/redux/slice/DialogSlice";
import {ExceptionResponse} from "../../../../proto/framework/ExceptionMessage";
import DimensionDialog from "../../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DimensionForm from "../../../../component/container/DimensionForm";
import ClearableTextField from "../../../../component/input/ClearableTextField";

type RenameDriverData = {
  fileId: string;
  filename: string;
}

export const RenameDriverFileDialog = React.memo(({
                                                    onDialogClose,
                                                    initialData,
                                                    open,
                                                    ...rest
                                                  }: CloseableDialogProps<RenameDriverData>) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose<{
    fileId: string;
    filename: string;
  }>(DialogNames.RenameDriverFileDialog, onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData);

  const fileId = initialData?.fileId || initialDataFromManager?.fileId || "";

  const [newName, setNewName, errorMessage, setErrorMessage, validate] = useFieldValidation((filename) => {
    if (filename.length === 0) {
      return "Filename cannot be empty";
    }
    if (!validateDriverFilename(filename)) {
      return "Filename is invalid";
    }
  }, initialData?.filename || initialDataFromManager?.filename || "");

  const inputRef = useAutoFocus([open], (inputRef) => {
    if (newName.includes(".")) {
      const index = newName.lastIndexOf(".");
      inputRef.current?.setSelectionRange(0, index);
    } else {
      inputRef.current?.setSelectionRange(0, newName.length);
    }
  });


  const handleEditName = async () => {
    if (!validate()) {
      return;
    }

    renameFile(fileId, newName).then(() => {
      handleClose(true, {
        fileId: fileId,
        filename: newName,
      });
    }).catch((error: ExceptionResponse) => {
      setErrorMessage(error.message);
    });
  }


  return <DimensionDialog
      dialogTitle={"Edit Filename"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleEditName}
      {...rest}>
    <DimensionForm
        infoMessage={"Renaming Your File. Make sure the name is within 255 characters and has no unsupported characters."}>
      <ClearableTextField value={newName} setValue={setNewName} label={"New Filename"}
                          onSubmit={handleEditName} errorMessage={errorMessage}
                          inputRef={inputRef}/>
    </DimensionForm>
  </DimensionDialog>
});
