// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/customer/CustomerInfo.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {UserRole, userRoleFromJSON, userRoleToJSON} from "../SecurityMessage";

export const protobufPackage = "proto.framework.customer";

/** Message on response of login */
export interface LoginResponse {
  /** customer name for display */
  name: string;
  /** avatar url for display */
  avatarUrl: string;
  /** username for login */
  username: string;
  /** role of the customer */
  role: UserRole;
  /** user id for login */
  userId: string;
}

export interface SetEmailRequest {
  email: string;
  otp: string;
}

export interface CustomerProfile {
  id: string;
  name: string;
  /** only customer can see his/her own email */
  email: string;
  avatarUrl: string;
  description: string;
  role: UserRole;
}

export interface QueryCustomerResponse {
  customers: CustomerProfile[];
}

function createBaseLoginResponse(): LoginResponse {
  return { name: "", avatarUrl: "", username: "", role: 0, userId: "" };
}

export const LoginResponse = {
  encode(message: LoginResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.avatarUrl !== "") {
      writer.uint32(18).string(message.avatarUrl);
    }
    if (message.username !== "") {
      writer.uint32(26).string(message.username);
    }
    if (message.role !== 0) {
      writer.uint32(32).int32(message.role);
    }
    if (message.userId !== "") {
      writer.uint32(42).string(message.userId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LoginResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.username = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginResponse {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      avatarUrl: isSet(object.avatarUrl) ? globalThis.String(object.avatarUrl) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
    };
  },

  toJSON(message: LoginResponse): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.avatarUrl !== "") {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginResponse>, I>>(base?: I): LoginResponse {
    return LoginResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginResponse>, I>>(object: I): LoginResponse {
    const message = createBaseLoginResponse();
    message.name = object.name ?? "";
    message.avatarUrl = object.avatarUrl ?? "";
    message.username = object.username ?? "";
    message.role = object.role ?? 0;
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseSetEmailRequest(): SetEmailRequest {
  return { email: "", otp: "" };
}

export const SetEmailRequest = {
  encode(message: SetEmailRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.otp !== "") {
      writer.uint32(18).string(message.otp);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SetEmailRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.otp = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetEmailRequest {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      otp: isSet(object.otp) ? globalThis.String(object.otp) : "",
    };
  },

  toJSON(message: SetEmailRequest): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.otp !== "") {
      obj.otp = message.otp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetEmailRequest>, I>>(base?: I): SetEmailRequest {
    return SetEmailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetEmailRequest>, I>>(object: I): SetEmailRequest {
    const message = createBaseSetEmailRequest();
    message.email = object.email ?? "";
    message.otp = object.otp ?? "";
    return message;
  },
};

function createBaseCustomerProfile(): CustomerProfile {
  return { id: "", name: "", email: "", avatarUrl: "", description: "", role: 0 };
}

export const CustomerProfile = {
  encode(message: CustomerProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.avatarUrl !== "") {
      writer.uint32(34).string(message.avatarUrl);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.role !== 0) {
      writer.uint32(48).int32(message.role);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CustomerProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomerProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomerProfile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      avatarUrl: isSet(object.avatarUrl) ? globalThis.String(object.avatarUrl) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
    };
  },

  toJSON(message: CustomerProfile): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.avatarUrl !== "") {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerProfile>, I>>(base?: I): CustomerProfile {
    return CustomerProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerProfile>, I>>(object: I): CustomerProfile {
    const message = createBaseCustomerProfile();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.avatarUrl = object.avatarUrl ?? "";
    message.description = object.description ?? "";
    message.role = object.role ?? 0;
    return message;
  },
};

function createBaseQueryCustomerResponse(): QueryCustomerResponse {
  return { customers: [] };
}

export const QueryCustomerResponse = {
  encode(message: QueryCustomerResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.customers) {
      CustomerProfile.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryCustomerResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryCustomerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customers.push(CustomerProfile.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryCustomerResponse {
    return {
      customers: globalThis.Array.isArray(object?.customers)
        ? object.customers.map((e: any) => CustomerProfile.fromJSON(e))
        : [],
    };
  },

  toJSON(message: QueryCustomerResponse): unknown {
    const obj: any = {};
    if (message.customers?.length) {
      obj.customers = message.customers.map((e) => CustomerProfile.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryCustomerResponse>, I>>(base?: I): QueryCustomerResponse {
    return QueryCustomerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryCustomerResponse>, I>>(object: I): QueryCustomerResponse {
    const message = createBaseQueryCustomerResponse();
    message.customers = object.customers?.map((e) => CustomerProfile.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
