import React from "react";
import {Stack, StackProps} from "@mui/material";
import {Typography} from "../../../component/wrapper/MuiWrappers";

const CopyrightFooter = ({...rest}: StackProps) => {
  return <Stack
      direction={"column"} justifyContent={"center"} alignItems={"center"} {...rest}>
    <Typography> <span>{`© ${new Date().getFullYear()} Xinyu Zhu. `}</span>{"All rights reserved."}</Typography>
  </Stack>
}

export default CopyrightFooter;