// This is a dialog Designed for General Document Upload Process
import * as React from 'react';
import Divider from "@mui/material/Divider";
import DimensionDialog, {DimensionDialogProps} from "./DimensionDialog";
import DimensionForm from "../container/DimensionForm";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {Stack} from "@mui/material";
import AlertContext from "../../context/AlertContext";
import {useDynamicList} from "ahooks";
import {FileMetaData} from "../../proto/framework/storage/StorageMessage";
import {print} from "../../api/util/Logging";
import Dropzone from "react-dropzone";
import UploadFileItem from "../../domain/storage/component/UploadFileItem";
import {Typography} from "../wrapper/MuiWrappers";
import {Condition} from "../container/Condition";
import {uploadFile} from "../../domain/storage/FileUploadUtil";

type FileUploadDialogProps =
    {
      prefixChildren?: React.ReactNode; // children before document upload area
      children?: React.ReactNode; // children after document upload area
      onUpload: (file: FileMetaData) => Promise<void>; // after file upload, this function will be called on the obtained file metadata
      isPublic: boolean;
      dialogTitle?: string;
      dialogStartNode?: React.ReactNode;
      primaryText?: string;
      // after file upload, this function will be called
      onDialogSubmit: (files: FileMetaData[], hasError: boolean) => Promise<void> | void;
    }
    & Omit<DimensionDialogProps, "children" | "dialogTitle" | "dialogStartNode" | "primaryText" | "onDialogSubmit">;
const FileUploadDialog = ({
                            dialogTitle = "Upload",
                            dialogStartNode = <Divider><FileUploadIcon className={"awesome-icon"}/></Divider>,
                            primaryText = "Upload",
                            onDialogSubmit,
                            onDialogClose,

                            prefixChildren,
                            children,
                            onUpload,
                            isPublic,

                            ...rest
                          }: FileUploadDialogProps) => {

  const files = useDynamicList<{ file: File, progress: number, error?: string }>([]);

  const [loading, setLoading] = React.useState(false);
  const {showAlert} = React.useContext(AlertContext);

  const submit = () => {
    if (files.list.length === 0) {
      return;
    }
    setLoading(true);
    const allUploadedFileMetaDatas: FileMetaData[] = [];
    const allUploadPromises = files.list.map((file, index) => {
      return uploadFile(file.file, isPublic, (progress) => {
        files.replace(index, {file: file.file, progress: progress});
      }, void 0, onUpload).then((fileMetaData: FileMetaData) => {
        allUploadedFileMetaDatas.push(fileMetaData);
      }).catch((error) => {
        print("upload error", error);
        showAlert("Failed to upload: " + file.file.name, "error");
        files.replace(index, {
          file: file.file,
          progress: 0,
          error: "Upload failed. Please try again."
        });
      })
    });

    return Promise.allSettled(allUploadPromises).then((values) => {
      const indexesToRemove = [];
      let hasError = false;
      for (let i = 0; i < values.length; i++) {
        if (values[i].status === "fulfilled") {
          indexesToRemove.push(i);
        } else {
          hasError = true;
        }
      }

      files.batchRemove(indexesToRemove);
      const submitResult = onDialogSubmit(allUploadedFileMetaDatas, hasError);

      if (submitResult instanceof Promise) {
        submitResult.finally(() => {
          setLoading(false);
          if (!hasError) {
            onDialogClose();
          }
        });
      } else {
        setLoading(false);
        if (!hasError) {
          onDialogClose();
        }
      }
    });
  }

  return <DimensionDialog
      dialogTitle={dialogTitle}
      dialogStartNode={dialogStartNode}
      primaryText={primaryText}
      onDialogSubmit={() => {
        return submit();
      }}
      onDialogClose={() => {
        if (loading) {
          // refresh the page
          window.location.reload();
        } else {
          onDialogClose();
        }
      }}
      {...rest}>

    <DimensionForm
        infoMessage={"Select Or Drop Files To Upload"}>
      {prefixChildren}
      <Stack width={"100%"} justifyContent={"center"}
             alignItems={"center"}>
        <Dropzone disabled={loading} onDrop={fileList => {
          if (!fileList || fileList.length === 0) {
            return;
          }
          files.merge(files.list.length, fileList.map(file => {
            return {file: file, progress: 0};
          }));
        }}>
          {({getRootProps, getInputProps}) => (
              <section {...getRootProps()} style={{width: "100%"}} className={"pointer"}>
                <Stack direction={"column"}
                       justifyContent={files.list.length === 0 ? "center" : "start"}
                       alignItems={"center"}
                       paddingX={2}
                       paddingY={2}
                       spacing={1} minHeight={"40dvh"} border={"dashed"}
                >
                  <Condition show={files.list.length === 0}>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
                      <FileUploadIcon sx={{
                        fontSize: "5rem"
                      }}/>
                      <Typography variant={"h3"}>Upload</Typography>
                    </Stack>
                  </Condition>

                  {files.list.map((file, index) => {
                    return <Stack direction={"column"} key={index} width={"100%"} spacing={2}>
                      <UploadFileItem file={file.file}
                                      progress={file.progress}
                                      onRemove={() => {
                                        if (loading) {
                                          return;
                                        }
                                        files.remove(index);
                                      }}
                                      error={file.error}
                      />
                      <Divider/>
                    </Stack>;
                  })}
                </Stack>
                <input type={"file"} multiple={true} {...getInputProps()}
                       hidden/>
              </section>
          )}
        </Dropzone>
      </Stack>
      {children}
    </DimensionForm>
  </DimensionDialog>
};

export default FileUploadDialog;