import {pb_pbPost, str_pbPost, throttle} from "../../../api/util/MyDimensionClient";
import {CustomerProfile, SetEmailRequest} from "../../../proto/framework/customer/CustomerInfo";
import store from "../../../api/redux/store";
import {
  setAvatarUrl as setAvatar,
  setCustomerName as setName,
  setUserRole
} from "../../../api/redux/slice/SettingSlice";

const customerAPIPrefix = '/api/v0/customer';

export const queryCustomerProfile = (userId?: string | null) => {
  return str_pbPost(customerAPIPrefix + "/query", CustomerProfile, userId);
};

export const setPrimaryEmail = throttle((email: string, otp: string) => {
  const setEmailRequest = SetEmailRequest.create();
  setEmailRequest.email = email;
  setEmailRequest.otp = otp;

  return pb_pbPost(customerAPIPrefix + "/setEmail", SetEmailRequest, CustomerProfile, setEmailRequest,{
    headers: {
      "Otp": otp
    }
  }).then(res => {
    store.dispatch(setUserRole(res.role));
    return res;
  });
});

export const setCustomerName = throttle((username: string) => {
  return str_pbPost(customerAPIPrefix + "/setUsername", CustomerProfile, username).then(res => {
    store.dispatch(setName(res.name));
    return res;
  });
});

export const setDescription = throttle((description: string) => {
  return str_pbPost(customerAPIPrefix + "/setDescription", CustomerProfile, description);
});

export const setAvatarUrl = throttle((avatarUrl: string) => {
  return str_pbPost(customerAPIPrefix + "/setAvatarUrl", CustomerProfile, avatarUrl).then(res => {
    store.dispatch(setAvatar(res.avatarUrl));
    return res;
  });
});