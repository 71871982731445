import {Box, BoxProps, Skeleton} from "@mui/material";
import React from "react";

// ImageContainer 是一个便捷实用图片容器组件，它可以以指定的宽高显示图片，同时将图片以最佳方式填充到容器中。
type ImageContainerProps = {
  src: string;
} & BoxProps;

const ImageContainer = ({src, sx, ...rest}: ImageContainerProps) => {
  const [loaded, setLoaded] = React.useState(false);


  return <>
    <Box component={"img"} src={src}
         onLoad={() => setLoaded(true)}
         sx={{
           objectFit: "cover",
           display: loaded ? "block" : "none",
           ...sx
         }}
         {...rest}>
    </Box>
    {!loaded && <Skeleton variant="rectangular" sx={{...sx}}/>}
  </>

}

export default ImageContainer;