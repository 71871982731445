import {LinearProgress, Stack, StackProps} from "@mui/material";
import {getFileIcon, getReadableFileSize} from "../FileUploadUtil";
import {Button, Typography} from "../../../component/wrapper/MuiWrappers";

type UploadFileItemProps = {
  onRemove: () => void;
  file: File;
  progress?: number;
  error?: string;
} & StackProps

const UploadFileItem = ({
                          onRemove,
                          file,
                          progress = 0,
                          error = void 0,
                          ...rest
                        }: UploadFileItemProps) => {
  return <Stack direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                {...rest}>
    {getFileIcon(file)}
    <Stack flexGrow={1} direction={"column"} alignItems={"start"} spacing={1}>

      <Stack direction={"row"} spacing={2}><Typography variant={"body1"}
                                                       enableTranslate={false}>{file.name} (
        {getReadableFileSize(file.size * progress / 100)}/{getReadableFileSize(file.size)})</Typography>
        {error && <Typography variant={"body1"} color={"error"}>{error}</Typography>}</Stack>
      <LinearProgress variant="determinate" value={progress} sx={{width: "100%", flexGrow: 1}}
                      color={error ? "error" :
                          progress === 0 ? "info" : progress === 100 ? "success" : "primary"}/>
    </Stack>
    <Button variant={"text"} onClick={(event) => {
      onRemove();
      event.stopPropagation();
    }}>{progress === 100 ? "Finished" : progress === 0 ? "Remove" : "Uploading"}</Button>
  </Stack>;

}

export default UploadFileItem;