import {useAppSelector} from "../../api/redux/hooks";
import {invokeDialog, selectDialogName,} from "../../api/redux/slice/DialogSlice";
import DemoDialog from "../../domain/index/component/DemoDialog";
import store from "../../api/redux/store";
import {DialogProps} from "@mui/material";
import SignInDialog from "../../domain/account/component/SignInDialog";
import SignUpDialog from "../../domain/account/component/SignUpDialog";
import EditUsernameDialog from "../../domain/account/component/EditUsernameDialog";
import EditPasswordDialog from "../../domain/account/component/EditPasswordDialog";
import CustomizeSettingDialog from "../../domain/website/setting/CustomizeSettingDialog";
import {EditAvatarDialog} from "../../domain/customer/component/EditAvatarDialog";
import EditEmailDialog from "../../domain/customer/component/EditEmailDialog";
import {EditCustomerNameDialog} from "../../domain/customer/component/EditCustomerNameDialog";
import {
  EditCustomerDescriptionDialog
} from "../../domain/customer/component/EditCustomerDescriptionDialog";
import {RenameDriverFileDialog} from "../../domain/driver/component/dialogs/RenameDriverFileDialog";
import {NewFolderDialog} from "../../domain/driver/component/dialogs/NewFolderDialog";
import {NewLinkFileDialog} from "../../domain/driver/component/dialogs/NewLinkFileDialog";
import {DriverUploadDialog} from "../../domain/driver/component/dialogs/DriverUploadDialog";
import {MoveDriverFileDialog} from "../../domain/driver/component/dialogs/MoveDriverFileDialog";
import {useEffect} from "react";
import {logAction} from "../../context/websocket/WebsocketHook";

// DialogManager Pattern is introduced to manage the dialog components in a single place.
// It ensures there is only one dialog component is open at a time.
// Each dialog component is a singleton and is only created once.

export type DialogName =
    ""
    | "DemoDialog"
    | "SignInDialog"
    | "SignUpDialog"
    | "EditUsernameDialog"
    | "EditPasswordDialog"
    | "CustomizeSettingDialog"
    | "EditAvatarDialog"
    | "EditCustomerDescriptionDialog"
    | "EditCustomerNameDialog"
    | "EditEmailDialog"
    | "RenameDriverFileDialog"
    | "NewFolderDialog"
    | "NewLinkFileDialog"
    | "DriverUploadDialog"
    | "MoveDriverFileDialog";

export type CloseDialogCallback = <T=any>(success: boolean, data?: T) => void;

// only specify T when initialData is required
export type CloseableDialogProps<T=any> = {
  onDialogClose?: CloseDialogCallback;
  initialData?: T;
} & DialogProps;

export const DialogNames = {
  DemoDialog: "DemoDialog" as DialogName,
  SignInDialog: "SignInDialog" as DialogName,
  SignUpDialog: "SignUpDialog" as DialogName,
  EditUsernameDialog: "EditUsernameDialog" as DialogName,
  EditPasswordDialog: "EditPasswordDialog" as DialogName,
  CustomizeSettingDialog: "CustomizeSettingDialog" as DialogName,
  EditAvatarDialog: "EditAvatarDialog" as DialogName,
  EditCustomerDescriptionDialog: "EditCustomerDescriptionDialog" as DialogName,
  EditCustomerNameDialog: "EditCustomerNameDialog" as DialogName,
  EditEmailDialog: "EditEmailDialog" as DialogName,
  RenameDriverFileDialog: "RenameDriverFileDialog" as DialogName,
  NewFolderDialog: "NewFolderDialog" as DialogName,
  NewLinkFileDialog: "NewLinkFileDialog" as DialogName,
  DriverUploadDialog: "DriverUploadDialog" as DialogName,
  MoveDriverFileDialog: "MoveDriverFileDialog" as DialogName,
}

const DialogManager = () => {
  const dialogType = useAppSelector(selectDialogName);

  useEffect(() => {
    if (dialogType === "") {
      logAction("close", "dialog");
    } else {
      logAction(dialogType, "dialog");
    }
  }, [dialogType]);

  return (
      <>
        {dialogType === DialogNames.DemoDialog && <DemoDialog
            open={dialogType === DialogNames.DemoDialog}></DemoDialog>}
        {dialogType === DialogNames.SignInDialog && <SignInDialog
            open={dialogType === DialogNames.SignInDialog}></SignInDialog>}
        {dialogType === DialogNames.SignUpDialog && <SignUpDialog
            open={dialogType === DialogNames.SignUpDialog}></SignUpDialog>}
        {dialogType === DialogNames.EditUsernameDialog && <EditUsernameDialog
            open={dialogType === DialogNames.EditUsernameDialog}></EditUsernameDialog>}
        {dialogType === DialogNames.EditPasswordDialog && <EditPasswordDialog
            open={dialogType === DialogNames.EditPasswordDialog}></EditPasswordDialog>}
        {dialogType === DialogNames.CustomizeSettingDialog && <CustomizeSettingDialog
            open={dialogType === DialogNames.CustomizeSettingDialog}></CustomizeSettingDialog>}
        {dialogType === DialogNames.EditEmailDialog && <EditEmailDialog
            open={dialogType === DialogNames.EditEmailDialog}></EditEmailDialog>}
        {dialogType === DialogNames.EditCustomerNameDialog && <EditCustomerNameDialog
            open={dialogType === DialogNames.EditCustomerNameDialog}></EditCustomerNameDialog>}
        {dialogType === DialogNames.EditCustomerDescriptionDialog && <EditCustomerDescriptionDialog
            open={dialogType === DialogNames.EditCustomerDescriptionDialog}></EditCustomerDescriptionDialog>}
        {dialogType === DialogNames.EditAvatarDialog && <EditAvatarDialog
            open={dialogType === DialogNames.EditAvatarDialog}></EditAvatarDialog>}
        {dialogType === DialogNames.RenameDriverFileDialog && <RenameDriverFileDialog
            open={dialogType === DialogNames.RenameDriverFileDialog}></RenameDriverFileDialog>}
        {dialogType === DialogNames.NewFolderDialog && <NewFolderDialog
            open={dialogType === DialogNames.NewFolderDialog}></NewFolderDialog>}
        {dialogType === DialogNames.NewLinkFileDialog && <NewLinkFileDialog
            open={dialogType === DialogNames.NewLinkFileDialog}></NewLinkFileDialog>}
        {dialogType === DialogNames.DriverUploadDialog && <DriverUploadDialog
            open={dialogType === DialogNames.DriverUploadDialog}></DriverUploadDialog>}
        {dialogType === DialogNames.MoveDriverFileDialog && <MoveDriverFileDialog
            open={dialogType === DialogNames.MoveDriverFileDialog}></MoveDriverFileDialog>}
      </>
  )
}

export const openDialog = <T = any>(dialogName: DialogName, initialData?: T) => {
  store.dispatch(invokeDialog({
    dialogName: dialogName,
    initialData: initialData,
    onDialogClose: () => {
      closeDialog();
    }
  }));
}

// open a dialog and return a promise that will be resolved when the dialog is closed
export const openDialogWithPromise = <T, R>(dialogName: DialogName, initialData?: T): Promise<{
  success: boolean,
  data?: R
}> => {
  return new Promise((resolve, reject) => {
    store.dispatch(invokeDialog({
      dialogName: dialogName,
      initialData: initialData,
      onDialogClose: (success: boolean, data?) => {
        resolve({success: success, data: data as R});
        closeDialog();
      }
    }));
  });
}


export const closeDialog = () => {
  store.dispatch(invokeDialog({
    dialogName: "", onDialogClose: () => {
    }
  }));
}

export default DialogManager;